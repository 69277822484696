import React from 'react';
import LanguageSwitcher from '../languageSwitcher/languageSwitcher';
import { UserDto } from '../../models/user.dto';
import { useTranslation } from 'react-i18next';

type ProfileInfoProps = {
   userData: UserDto;
};

const ProfileInfo = ({ userData }: ProfileInfoProps) => {
   const [translate] = useTranslation(['account', 'common']);

   return (
      <>
         <div>
            {translate('common:common.firstName')}: <b>{userData.firstName}</b>
         </div>
         <div>
            {translate('common:common.lastName')}: <b>{userData.lastName}</b>
         </div>
         <div>
            {translate('common:common.email')}: <b>{userData.email}</b>
         </div>
         <div>
            {translate('common:settings.changeLanguage')}
            <div className='language'>
               <LanguageSwitcher
                  userData={userData}
               />
            </div>
         </div>
      </>
   );
};

export default ProfileInfo;
