import { BankAccountDto } from './bank-account.dto';
import { ApprovalDto } from './approval.dto';
import {RejectionDto} from './rejection.dto';
import { VatCodeDto } from './vat-code.dto';


export interface InvoiceDto {
   invoiceId: number;
   company: string;
   bankAccount: BankAccountDto;
   amount: number;
   currency: string;
   description: string;
   dtaDate: string;
   paymentDate: string;
   vat : VatCodeDto;
   vatCode: string;
   accountingNumber: string;
   costCenter: string;
   approvals: ApprovalDto[];
   rejections: RejectionDto[];
   createdAt: string;
   accountingRemark?: string;
}

export interface InvoicePaginationDto {
   content: InvoiceDto[];
   numberOfElements: number;
   totalElements: number;
   totalPages: number
}

export const INVOICE_GENERATION_FILER_PENDING = 'PENDING';
export const INVOICE_GENERATION_FILER_GENERATED = 'GENERATED';
export const INVOICE_GENERATION_FILER_ALL = 'ALL';

export type InvoiceGenerationFilters =
   typeof INVOICE_GENERATION_FILER_PENDING |
   typeof INVOICE_GENERATION_FILER_GENERATED |
   typeof INVOICE_GENERATION_FILER_ALL;

export const INVOICE_PAGINATION_DEFAULT_SIZE = 50;
