import React, { useEffect, useState } from 'react';
import {
   useAddBankAccountMutation,
   useDeleteBankAccountMutation,
   useGetBankAccountsQuery,
   useGetDefaultBankAccountQuery,
   useSetDefaultBankAccountMutation,
   useUpdateBankAccountMutation,
} from '../../../store/bank-accounts/bank-accounts.api';
import { Button } from 'react-bootstrap';
import { PencilFill, TrashFill } from 'react-bootstrap-icons';
import ModalContainer from '../../layout/modal-container';
import AddEditAccount from './add-edit-account';
import { BankAccountDto } from '../../../models/bank-account.dto';
import { useForm } from 'react-hook-form';
import './manage-bank-account.scss';
import { useTranslation } from 'react-i18next';

const ManageBankAccounts = () => {
   const [translate] = useTranslation(['account', 'common']);
   const { data: bankAccounts } = useGetBankAccountsQuery();
   const { data: defaultBankAccount } = useGetDefaultBankAccountQuery();
   const [addNewBankAccount] = useAddBankAccountMutation();
   const [updateBankAccount] = useUpdateBankAccountMutation();
   const [deleteBankAccount] = useDeleteBankAccountMutation();
   const [setDefaultBankAccount] = useSetDefaultBankAccountMutation();
   const [accountErrors, setAccountErrors] = useState([]);
   const [showEditAccountModal, setShowEditAccountModal] = useState(false);
   const [showDeleteAccountModal, setShowDeleteAccountModal] = useState(false);
   const [editAccount, setEditAccount] = useState<BankAccountDto | null | undefined>(undefined);
   const [deleteAccount, setDeleteAccount] = useState<BankAccountDto | null>(null);
   const {
      register,
      reset,
      formState: { errors, isValid },
      handleSubmit,
      setValue,
      control,
   } = useForm<BankAccountDto>();

   const onSubmit = handleSubmit((data) =>
     editAccount
       ? updateBankAccount({bankAccountId: editAccount.bankAccountId as number, data}).then(
         (res) => {
            handleAfterSubmit(res);
         },
       )
       : addNewBankAccount(data).then((res) => {
          handleAfterSubmit(res);
       }),
   );

   const handleAfterSubmit = (res) => {
      'error' in res ? setAccountErrors(res.error.data.fieldErrors) : closeEditModal();
   };

   const closeEditModal = () => {
      setShowEditAccountModal(false);
      setEditAccount(undefined);
   };

   useEffect(() => {
      if (editAccount !== undefined) {
         setAccountErrors([]);
         setShowEditAccountModal(true);
      }
   }, [editAccount]);

   useEffect(() => {
      if (deleteAccount !== null) {
         setShowDeleteAccountModal(true);
      }
   }, [deleteAccount]);

   return (
      <>
         <div className={'p-lg-3'}>
            <div className={'mb-5'}>
               <Button onClick={() => setEditAccount(null)}>
                  {translate('account:bankAccount.addBankAccount')}
               </Button>
            </div>
            {bankAccounts &&
               bankAccounts.map((account) => (
                  <div
                     className={
                        'd-flex justify-content-between align-items-center border-bottom px-3 py-2 account'
                     }
                     key={account.bankAccountId}
                  >
                     <div>
                        <b>{account.name}</b>
                     </div>

                     <div className={'d-flex justify-content-between  align-items-center'}>
                        <div>
                           {account.bankAccountId === defaultBankAccount?.bankAccountId ? (
                              <div className={'default-bank-account'}>
                                 {translate('common:common.default')}
                              </div>
                           ) : (
                              <Button
                                 variant={'outline-dark'}
                                 onClick={() => setDefaultBankAccount(account.bankAccountId!)}
                              >
                                 {translate('common:common.setAsDefault')}
                              </Button>
                           )}
                        </div>

                        <PencilFill
                           onClick={() => setEditAccount(account)}
                           className={'cursor-pointer mx-4 profileButton'}
                           size={'30px'}
                        />
                        <TrashFill
                           onClick={() => {
                              setDeleteAccount(account);
                           }}
                           className={'cursor-pointer profileButton'}
                           size={'30px'}
                        />
                     </div>
                  </div>
               ))}
         </div>
         {showEditAccountModal && (
            <ModalContainer
               modalContent={
                  <AddEditAccount
                     accountErrors={accountErrors}
                     setValue={setValue}
                     errors={errors}
                     onSubmit={onSubmit}
                     register={register}
                     editAccount={editAccount}
                     reset={reset}
                     control={control}
                  />
               }
               modalHeader={
                  editAccount
                     ? translate('account:bankAccount.editAccount')
                     : translate('account:bankAccount.addAccount')
               }
               submitButtonText={translate('common:common.save')}
               show={true}
               onSubmit={onSubmit}
               submitButtonEnabled={isValid}
               onCancel={() => closeEditModal()}
            ></ModalContainer>
         )}

         {showDeleteAccountModal && (
            <ModalContainer
               modalContent={translate('account:bankAccount.deleteBackAccountWarning', {
                  deleteAccount,
               })}
               modalHeader={translate('account:bankAccount.deleteBankAccount')}
               submitButtonText={translate('common:common.delete')}
               show={true}
               onSubmit={() => {
                  deleteBankAccount(deleteAccount?.bankAccountId);
                  setShowDeleteAccountModal(false);
               }}
               onCancel={() => {
                  setDeleteAccount(null);
                  setShowDeleteAccountModal(false);
               }}
            ></ModalContainer>
         )}
      </>
   );
};

export default ManageBankAccounts;
