import { createApi } from '@reduxjs/toolkit/query/react';
import { AccountingNumberDto } from '../../models/accounting-number.dto';
import { baseQueryWithRedirect } from '../base-query-with-redirect';

export const accountingNumbersApi = createApi({
   reducerPath: 'accountNumbers/api',
   baseQuery: baseQueryWithRedirect,
   endpoints: (build) => ({
      getAccountingNumbers: build.query<AccountingNumberDto[], void>({
         query: () => ({
            url: 'accounting_number',
         }),
      }),
      getAccountingNumbersInUse: build.query<AccountingNumberDto[], void>({
         query: () => ({
            url: 'accounting_number',
            params: { in_use: true },
         }),
      }),
   }),
});

export const { useGetAccountingNumbersQuery, useLazyGetAccountingNumbersInUseQuery } =
   accountingNumbersApi;
