import React, { useEffect, useRef, useState } from 'react';
import {Modal, Spinner} from 'react-bootstrap';
import {Document, pdfjs, Page} from 'react-pdf';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import {XCircle} from 'react-bootstrap-icons';
import './pdf-viewer.scss';
import { getBackendURL } from '../../../utils/loginProvider';
import { useReactToPrint } from 'react-to-print';
import { ReactComponent as PrintIcon } from '../../../assets/print.svg';

interface Props {
  invoiceId: number;
  onHide: () => void;
  show: true;
}

const PdfViewer = ({invoiceId, onHide, show}: Props) => {
  const [numPages, setNumPages] = useState(null);
  const documentRef = useRef<HTMLDivElement>(null);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  useEffect(() => {
    pdfjs.GlobalWorkerOptions.workerSrc =`https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  }, []);

  const file = {
    url: getBackendURL() + `api/invoice/download/${invoiceId}`,
    httpHeaders: {
      'authorization': `Bearer ${localStorage.getItem('accessToken')}`
    }
  };

  const pages = numPages ? Array.from({length: numPages}, (_, i) => i + 1) : [];

  const handlePrint = useReactToPrint({
    content: () => documentRef.current,
  });

  return (
     <Modal show={show}
            onHide={onHide}
            className='pdf-viewer'
            contentClassName='content'
     >
       {numPages && <XCircle className='close' onClick={onHide}></XCircle>}
       <PrintIcon  className='print' onClick={handlePrint} />
       <div ref={documentRef}>
         <Document
            file={file}
            onLoadSuccess={onDocumentLoadSuccess}
            canvasBackground='transparent'
            loading={<Spinner className='spinner' />}
         >
           {pages.map(n => (
              <Page key={n}
                    pageNumber={n}
                    className='page'
                    width='800'
                    height='1000' />
           ))}

         </Document>
       </div>
     </Modal>
  );
};
export default PdfViewer;