import {Button, Modal} from 'react-bootstrap';
import React from 'react';
import {Trans, useTranslation} from 'react-i18next';

interface Props {
    show: boolean;
    onHide: () => void;
    onConfirm: () => void;
    currentAmount: string;
    newAmount: string;
}
const ConfirmationDialog = ({show, onHide, currentAmount, newAmount, onConfirm}: Props) => {
    const [translate] = useTranslation(['account', 'common', 'invoice']);

    return <Modal show={show} onHide={onHide}>

            <Modal.Header>
                <Modal.Title>{translate('common:common.pleaseConfirm')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Trans i18nKey="invoice:invoice.changingAmount" tOptions={{newAmount, currentAmount}} />
            </Modal.Body>
            <Modal.Footer>

                <Button variant='secondary' onClick={onHide}>
                    {translate('common:common.cancel')}
                </Button>
                <Button variant='primary' onClick={onConfirm} >
                    {translate('common:common:confirm')}
                </Button>
            </Modal.Footer>
    </Modal>;
}

export default ConfirmationDialog;