import {OverlayTrigger, Tooltip} from 'react-bootstrap';
import React from 'react';

interface Props {
  firstName: string;
  lastName: string;
  email: string;
}

const UserName = ({firstName, lastName, email}: Props) => (
  <OverlayTrigger
    key={'top'}
    placement={'top'}
    overlay={
      <Tooltip className='approval-tooltip'>
        {email}
      </Tooltip>
    }
  >
    <b>{firstName} {lastName}</b>
  </OverlayTrigger>
)

export default UserName;