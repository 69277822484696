import { createApi } from '@reduxjs/toolkit/query/react';
import { InvoiceDto, InvoicePaginationDto } from '../../models/invoice.dto';
import { baseQueryWithRedirect } from '../base-query-with-redirect';
import {AmountChangedDto} from '../../models/amount-changed.dto';

type UpdateInvoiceBody = {
   invoiceId: number;
   data: InvoiceDto;
};

type InvoiceId = {
   invoiceId: number;
};

type FetchAmountChanged = {
   invoiceId: number;
};

type RejectInvoice = {
   invoiceId: number;
   reason: string;
};

type ExportInvoice = {
   invoiceIds: number[];
   paymentDate: string;
   fileType: string;
};

export type FilterInvoiceBody = {
   fromDate: string;
   toDate: string;
   fromAmount: string;
   toAmount: string;
   name: string;
   costCenter: string;
   accountingNumber: string;
   generationDateFilter: 'ALL' | 'GENERATED' | 'PENDING';
   sortBy?: string;
   ascending?: boolean;
   page: number;
   size: number;
};

const downloadFileHandler = async (response) => {
   const link = document.createElement('a');
   link.href = window.URL.createObjectURL(await response.blob());
   link.setAttribute('download', getFileName(response));
   document.body.appendChild(link);
   link.click();
   document.body.removeChild(link);
};

const getFileName = (response) => {
   const contentDisposition = response.headers.get('content-disposition');
   return contentDisposition?.split('filename=')[1].split(';')[0];
};

const buildFilterQuery = (baseUrl, params) => {
   const baseParams = new URLSearchParams({
      amountFrom: params.fromAmount,
      amountTo: params.toAmount,
      paymentDateFrom: params.fromDate,
      paymentDateTo: params.toDate,
      company: params.name,
      generationDateFilter: params.generationDateFilter,
      sortBy: params.sortBy,
      ascending: params.ascending,
      page: params.page,
      size: params.size
   });

   if (params.costCenter) {
      baseParams.append('costCenter', params.costCenter);
   }

   if (params.accountingNumber) {
      baseParams.append('accountingNumber', params.accountingNumber);
   }

   return baseUrl + '?' + baseParams.toString();
};

export const invoicesApi = createApi({
   reducerPath: 'invoices/api',
   tagTypes: ['Invoice', 'UpdateInvoice'],
   baseQuery: baseQueryWithRedirect,
   endpoints: (build) => ({
      filterInvoices: build.query<InvoicePaginationDto, FilterInvoiceBody>({
         query: (params) => ({
            url: buildFilterQuery('invoice', params),
            method: 'GET',
         }),
         providesTags: ['Invoice'],
      }),

      deleteInvoice: build.mutation<UpdateInvoiceBody, InvoiceId>({
         query: ({ invoiceId }: InvoiceId) => ({
            url: `invoice/${invoiceId}`,
            method: 'POST'
         }),
         invalidatesTags: (result, error, arg) => [{ type: 'Invoice', invoiceId: arg.invoiceId }],
      }),

      updateInvoice: build.mutation<UpdateInvoiceBody, UpdateInvoiceBody>({
         query: ({ invoiceId, data }: UpdateInvoiceBody) => ({
            url: `invoice/${invoiceId}`,
            method: 'PUT',
            body: data,
         }),
         invalidatesTags: (result, error, arg) => [{ type: 'Invoice', invoiceId: arg.invoiceId }],
      }),

      exportInvoices: build.mutation<ExportInvoice, ExportInvoice>({
         query: ({ invoiceIds, paymentDate }: ExportInvoice) => ({
            url: 'payment',
            method: 'POST',
            body: { invoiceIds, paymentDate },
            responseHandler: downloadFileHandler,
            cache: 'no-cache',
         }),
         invalidatesTags: ['Invoice'],
      }),

      exportCsv: build.mutation<void, FilterInvoiceBody>({
         query: (params) => ({
            url: buildFilterQuery('invoice/csv', params),
            method: 'GET',
            responseHandler: downloadFileHandler,
            cache: 'no-cache',
         }),
      }),

      downloadInvoice: build.mutation({
         query: ({ invoiceId }: InvoiceId) => ({
            url: `invoice/download/${invoiceId}`,
            method: 'GET',
            responseHandler: downloadFileHandler,
            cache: 'no-cache',
         }),
      }),

      downloadInvoices: build.mutation<number[], number[]>({
         query: (invoiceIds: number[]) => ({
            url: 'invoice/download',
            method: 'POST',
            body: invoiceIds,
            responseHandler: downloadFileHandler,
            cache: 'no-cache',
         }),
      }),

      approveInvoice: build.mutation({
         query: ({ invoiceId }: InvoiceId) => ({
            url: `invoice/approval/${invoiceId}`,
            method: 'POST',
            cache: 'no-cache',
         }),
         invalidatesTags: ['Invoice'],
      }),

      approveInvoices: build.mutation<number[], number[]>({
         query: (invoiceIds: number[]) => ({
            url: 'invoice/approval',
            method: 'POST',
            body: invoiceIds,
         }),
         invalidatesTags: ['Invoice'],
      }),

      rejectInvoice: build.mutation({
         query: ({ invoiceId, reason }: RejectInvoice) => ({
            url: `invoice/reject/${invoiceId}`,
            method: 'POST',
            cache: 'no-cache',
            body: reason
         }),
         invalidatesTags: ['Invoice'],
      }),

      getAmountChanged: build.query<AmountChangedDto[], FetchAmountChanged>({
         query: ({invoiceId}: FetchAmountChanged) => ({
            url: `invoice/amount_changed/${invoiceId}`,
            method: 'GET',
         }),
         providesTags: ['Invoice']
      }),
   }),
});

export const {
   useFilterInvoicesQuery,
   useDeleteInvoiceMutation,
   useUpdateInvoiceMutation,
   useExportInvoicesMutation,
   useExportCsvMutation,
   useDownloadInvoiceMutation,
   useDownloadInvoicesMutation,
   useApproveInvoiceMutation,
   useApproveInvoicesMutation,
   useRejectInvoiceMutation,
   useGetAmountChangedQuery,
} = invoicesApi;
