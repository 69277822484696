/*
   Ff you run project with npm run build it will result in azure as IDP and non-custom config,
   Custom config are used for neff and jodernkellerei clients, other clients ztag, vtag and staging env use non-custom config (docker-compose.yml)
 */

const loginProvider: string = process.env.REACT_APP_LOGIN_PROVIDER ?? '';
const configType: string = process.env.REACT_APP_CONFIG_TYPE ?? '';
const customConfig = configType === 'custom';

export const isKeycloakIDP = loginProvider === 'keycloak';
export const isAzureIDP = !isKeycloakIDP;

export const getAppName = () => customConfig ? process.env.REACT_APP_NAME : window.appConfig.APP_NAME;
export const getBackendURL = () => customConfig ? process.env.REACT_APP_BACKEND_URL : window.appConfig.BACKEND_URL;

export const getMsalAuthClientId = () => customConfig ? process.env.REACT_APP_AZURE_CLIENT_ID : window.appConfig.CLIENT_ID;
export const getMsalAuthTenantId = () => customConfig ? process.env.REACT_APP_AZURE_TENANT_ID : window.appConfig.TENANT_ID;
export const getMsalAuthRedirectUrl = () => customConfig ? process.env.REACT_APP_AZURE_REDIRECT_URL : window.appConfig.REDIRECT_URL;