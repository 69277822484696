import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import enCommon from '../translations/en/common.json';
import deCommon from '../translations/de/common.json';
import enAccount from '../translations/en/account.json';
import deAccount from '../translations/de/account.json';
import enInvoice from '../translations/en/invoice.json';
import deInvoice from '../translations/de/invoice.json';
import enCountry from '../translations/en/country.json';
import deCountry from '../translations/de/country.json';
import { LanguageCodes } from '../config/enums/Language';
import { getLanguageFromLocalStorage } from '../translations/langUtils';

i18next.use(initReactI18next).init({
   initImmediate: false,
   fallbackLng: LanguageCodes.DEFAULT,
   lng: getLanguageFromLocalStorage(),
   interpolation: {
      escapeValue: false,
   },
   supportedLngs: [LanguageCodes.ENGLISH, LanguageCodes.DEUTSCH],
   resources: {
      en: {
         common: enCommon,
         account: enAccount,
         invoice: enInvoice,
         country: deCountry,
      },
      de: {
         common: deCommon,
         account: deAccount,
         invoice: deInvoice,
         country: enCountry,
      },
   },
});

export default i18next;
