import {createApi} from '@reduxjs/toolkit/query/react';
import {baseQueryWithRedirect} from '../base-query-with-redirect';
import {SettingsDto} from '../../models/SettingsDto';

export const settingsApi = createApi({
  reducerPath: 'settings/api',
  baseQuery: baseQueryWithRedirect,
  tagTypes: ['Settings'],
  endpoints: (build) => ({
    getSettings: build.query<SettingsDto, void>({
      query() {
        return {
          url: 'ui-settings',
        };
      },
    }),
    patchSettings: build.mutation<void, SettingsDto>({
      query: (settingsDto: SettingsDto) => ({
        url: 'ui-settings',
        method: 'PATCH',
        body: settingsDto,
      }),

      invalidatesTags: [],
    })
  }),
});

export const { useGetSettingsQuery, usePatchSettingsMutation } = settingsApi;