import { createApi } from '@reduxjs/toolkit/query/react';
import { UserDto } from '../../models/user.dto';
import { baseQueryWithRedirect } from '../base-query-with-redirect';
import {setLanguageToLocalStorage} from '../../translations/langUtils';
import i18n from '../../services/i18n';

const userResponseHandler = async (response) => {
   const user = await response.json();
   setLanguageToLocalStorage(user?.language)
   i18n.changeLanguage(user?.language);
   return user;
}

export const userApi = createApi({
   reducerPath: 'user/api',
   baseQuery: baseQueryWithRedirect,
   tagTypes: ['User'],
   endpoints: (build) => ({
      getUserData: build.query<UserDto, void>({
         query() {
            return {
               url: 'user',
               responseHandler: userResponseHandler
            };
         },
         providesTags: ['User']
      }),
      updateUserData: build.mutation<UserDto, UserDto>({
         query: (user: UserDto) => ({
            url: 'user',
            method: 'PUT',
            body: user 
         }),
         invalidatesTags: ['User']
      })
   }),
});

export const {
   useGetUserDataQuery,
   useUpdateUserDataMutation
} = userApi;
