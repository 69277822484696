import React from 'react';
import TopBar from '../../components/layout/top-bar/top-bar';
import { Outlet } from 'react-router-dom';

const RoutesWithNavbar = () => {
   return (
      <>
         <TopBar />
         <Outlet />
      </>
   );
};

export default RoutesWithNavbar;
