import {InvoiceDto} from '../../../models/invoice.dto';
import {
  useGetAccountingNumbersQuery,
  useLazyGetAccountingNumbersInUseQuery
} from '../../../store/accounting-numbers/accounting-numbers.api';
import {useUpdateInvoiceMutation} from '../../../store/invoices/invoices.api';
import {useTranslation} from 'react-i18next';
import {AccountingNumberDto} from '../../../models/accounting-number.dto';
import DropdownWithFilter from '../../elements/dropdown-with-filter/dropdown-with-filter';
import {Button} from 'react-bootstrap';
import React from 'react';

const AccountingNumber = ({invoice}: {invoice: InvoiceDto}) => {
  const { data: accountingNumbers } = useGetAccountingNumbersQuery();
  const [getAccountNumbersInUse] = useLazyGetAccountingNumbersInUseQuery();

  const [updateInvoice] = useUpdateInvoiceMutation();
  const [translate] = useTranslation(['account', 'common', 'invoice']);

  const handleInvoiceNumberUpdate = () => {
    getAccountNumbersInUse();
  };

  const handleAccountNumberChange = (accountingNumber: string, invoice: InvoiceDto) => {
    updateInvoice({invoiceId: invoice.invoiceId, data: {...invoice, accountingNumber}}).then(
      handleInvoiceNumberUpdate,
    );
  };
  const handleAccountNumberSelection = (accountNumber: AccountingNumberDto) => {
    handleAccountNumberChange(accountNumber.accountingNumber, invoice);
  };

  const selectedAccountingNumber = accountingNumbers?.find(
    (account) => invoice.accountingNumber === account.accountingNumber,
  );

  function getAdditionalMenuBottomElement() {
    return <Button
      className={'justify-content-center'}
      variant='dark'
      onClick={() =>
        handleAccountNumberSelection({accountingNumber: '6790', name: ''})
      }
    >
      {translate('invoice:invoice.collectiveEntry')}
    </Button>;
  }

  return (
    <td className={'filter-dropdown accounting-number'}>
      <DropdownWithFilter

        className={ `toggle ${invoice.accountingNumber ? '' : 'empty-value'}`}
        items={accountingNumbers || []}
        selectedValue={invoice.accountingNumber}
        tooltip={selectedAccountingNumber ? selectedAccountingNumber.name ?? '' : ''}
        getMenuItemValue={(accountingNumber: AccountingNumberDto) =>
          `${accountingNumber.accountingNumber} - ${accountingNumber.name}`
        }
        handleChange={handleAccountNumberSelection}
        handleFilter={(accountNumbers, filterValue) =>
          accountNumbers.filter(
            (accountNumber: AccountingNumberDto) =>
              accountNumber.accountingNumber
                .toLowerCase()
                .includes(filterValue.toLowerCase()) ||
              accountNumber.name.toLowerCase().includes(filterValue.toLowerCase()),
          )
        }
        additionalMenuBottomElement={getAdditionalMenuBottomElement()}
      ></DropdownWithFilter>
    </td>
  )
}

export default AccountingNumber;