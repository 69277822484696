import React, { FC } from 'react';
import AzureAppRoutes from './config/routes/AzureAppRoutes';
import { QueryStatus } from '@reduxjs/toolkit/query';
import { useAppSelector } from './hooks/redux';
import {Spinner} from 'react-bootstrap';
import { ToastContainer } from 'react-toastify';
import {AuthenticatedTemplate, UnauthenticatedTemplate} from '@azure/msal-react';
import Login from './components/pages/login/login';


declare global {
  interface Window {
    appConfig: {
      SCOPE: string;
      CLIENT_ID: string,
      REDIRECT_URL: string,
      TENANT_ID: string,
      BACKEND_URL: string,
      APP_NAME: string
    };
  }
}

const App: FC = () => {

   const isLoading = useAppSelector((state) =>
        Object.values(state).some(
         (api) =>
            Object.values(api.queries).some((query) => query?.status === QueryStatus.pending) ||
            Object.values(api.mutations)
              .filter(a => a?.endpointName !== 'patchSettings')
              .some(
               (mutation) => mutation?.status === QueryStatus.pending,
            ),
      ),
   );

   return (
      <>
        <AuthenticatedTemplate>
         {isLoading ? (
            <div className={'loading-indicator d-flex justify-content-center align-items-center'}>
               <Spinner animation={'border'} />
            </div>
         ) : null}
         <ToastContainer autoClose={5000} theme={'dark'} position={'bottom-right'} />
         <AzureAppRoutes />
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
          <Login />
        </UnauthenticatedTemplate>

      </>
   );
}

export default App;
