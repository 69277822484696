import React, { ReactNode } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

interface PropsType {
   show: boolean;
   modalContent: ReactNode;
   modalHeader: string;
   onSubmit: () => void;
   onCancel?: () => void;
   submitButtonText: string;
   submitButtonEnabled?: boolean;
   dialogClassName?: string;
}

const ModalContainer = ({
   show = false,
   submitButtonEnabled = true,
   dialogClassName = '',
   modalContent,
   modalHeader,
   onSubmit,
   onCancel,
   submitButtonText,
}: PropsType) => {
   const [translate] = useTranslation('common');

   return (
      <Modal show={show} onHide={onCancel} dialogClassName={dialogClassName}>
         <Modal.Header>
            <Modal.Title>{modalHeader}</Modal.Title>
         </Modal.Header>
         <Modal.Body>{modalContent}</Modal.Body>
         <Modal.Footer>
            {onCancel && (
               <Button variant='secondary' onClick={onCancel}>
                  {translate('common.cancel')}
               </Button>
            )}
            <Button disabled={!submitButtonEnabled} variant='primary' onClick={onSubmit}>
               {submitButtonText}
            </Button>
         </Modal.Footer>
      </Modal>
   );
};

export default ModalContainer;
