import {Form} from 'react-bootstrap';
import {InvoiceDto} from '../../../../models/invoice.dto';
import {useEffect, useRef, useState} from 'react';
import {useUpdateInvoiceMutation} from '../../../../store/invoices/invoices.api';
import './accounting-remark.scss';

interface Props {
    invoice: InvoiceDto;
}

const AccountingRemark = ({invoice}: Props) => {
    const [accountingRemark, setAccountingRemark] = useState(invoice.accountingRemark);
    const inputRef = useRef<HTMLInputElement>(null);
    const [updateInvoice] = useUpdateInvoiceMutation();

    useEffect(() => {
        setAccountingRemark(invoice.accountingRemark);
    }, [invoice]);

    function isAmountChanged() {
        return accountingRemark != invoice.accountingNumber;
    }

    const updateAccountingRemark = () => {
        if (isAmountChanged()) {
            updateInvoice({ invoiceId: invoice.invoiceId, data: { ...invoice, accountingRemark } });
        }
    }

    const onKeyDown = event => {
        if (event.key === 'Enter') {
            updateAccountingRemark();
        }
    };

    const onChange =  e => {
        const {value} = e.target;
        if (value.length < 25) {
            setAccountingRemark(value);
        }
    };

    return <>
        <Form.Control
            className='accounting-remark'
            onKeyDown={onKeyDown}
            onBlur={updateAccountingRemark}
            onChange={onChange}
            plaintext
            value={accountingRemark}
            ref={inputRef}
        />
    </>;
}

export default AccountingRemark;