import { createApi } from '@reduxjs/toolkit/query/react';
import { CostCenterDto } from '../../models/cost-center.dto';
import { baseQueryWithRedirect } from '../base-query-with-redirect';

export const costCentersApi = createApi({
   reducerPath: 'costCenters/api',
   baseQuery: baseQueryWithRedirect,
   endpoints: (build) => ({
      getCostCenters: build.query<CostCenterDto[], void>({
         query: () => ({
            url: 'cost_center',
         }),
      }),
      getCostCentersInUse: build.query<CostCenterDto[], void>({
         query: () => ({
            url: 'cost_center',
            params: { in_use: true },
         }),
      }),
   }),
});

export const { useGetCostCentersQuery, useLazyGetCostCentersInUseQuery } = costCentersApi;
