import { configureStore } from '@reduxjs/toolkit';
import { bankAccountsApi } from './bank-accounts/bank-accounts.api';
import { invoicesApi } from './invoices/invoices.api';
import { rtkQueryErrorLogger } from './rtkQueryErrorLogger';
import { accountingNumbersApi } from './accounting-numbers/accounting-numbers.api';
import { costCentersApi } from './cost-centers/cost-centers.api';
import { userApi } from './user/user.api';
import {settingsApi} from './settings/settings.api';
import { vatCodesApi } from './vat-codes/vat-codes.api';

export const store = configureStore({
   reducer: {
      [invoicesApi.reducerPath]: invoicesApi.reducer,
      [bankAccountsApi.reducerPath]: bankAccountsApi.reducer,
      [accountingNumbersApi.reducerPath]: accountingNumbersApi.reducer,
      [costCentersApi.reducerPath]: costCentersApi.reducer,
      [userApi.reducerPath]: userApi.reducer,
      [settingsApi.reducerPath]: settingsApi.reducer,
      [vatCodesApi.reducerPath]: vatCodesApi.reducer
   },
   middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware()
         .concat(bankAccountsApi.middleware)
         .concat(accountingNumbersApi.middleware)
         .concat(costCentersApi.middleware)
         .concat(invoicesApi.middleware)
         .concat(userApi.middleware)
         .concat(settingsApi.middleware)
         .concat(vatCodesApi.middleware)
         .concat(rtkQueryErrorLogger)
});

export type RootState = ReturnType<typeof store.getState>;
