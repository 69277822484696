import { createApi } from '@reduxjs/toolkit/query/react';
import { BankAccountDto } from '../../models/bank-account.dto';
import { baseQueryWithRedirect } from '../base-query-with-redirect';

type UpdateBankAccountBody = {
   bankAccountId: number;
   data: BankAccountDto;
};

export const bankAccountsApi = createApi({
   reducerPath: 'bankAccounts/api',
   tagTypes: ['Account', 'Default Account'],
   baseQuery: baseQueryWithRedirect,
   endpoints: (build) => ({
      getBankAccounts: build.query<BankAccountDto[], void>({
         query: () => ({
            url: 'bank_account',
         }),
         providesTags: ['Account'],
      }),

      addBankAccount: build.mutation<BankAccountDto, BankAccountDto>({
         query: (account: BankAccountDto) => ({
            url: 'bank_account',
            method: 'POST',
            body: account,
         }),
         invalidatesTags: ['Account'],
      }),

      updateBankAccount: build.mutation<UpdateBankAccountBody, UpdateBankAccountBody>({
         query: ({ bankAccountId, data }: UpdateBankAccountBody) => ({
            url: `/bank_account/${bankAccountId}`,
            method: 'PUT',
            body: { bankAccountId, ...data },
         }),
         invalidatesTags: (result, error, arg) => [
            { type: 'Account', bankAccountId: arg.bankAccountId },
         ],
      }),

      deleteBankAccount: build.mutation({
         query: (id) => ({
            url: `/bank_account/${id}`,
            method: 'POST',
         }),
         invalidatesTags: (result, error, arg) => [
            { type: 'Account', bankAccountId: arg.bankAccountId },
         ],
      }),

      getDefaultBankAccount: build.query<BankAccountDto, void>({
         query: () => ({
            url: 'bank_account/default',
         }),
         providesTags: ['Default Account'],
      }),

      setDefaultBankAccount: build.mutation<number, number>({
         query: (bankAccountId: number) => ({
            url: `/bank_account/default/${bankAccountId}`,
            method: 'PUT',
         }),
         invalidatesTags: ['Default Account'],
      }),
   }),
});

export const {
   useGetBankAccountsQuery,
   useAddBankAccountMutation,
   useUpdateBankAccountMutation,
   useDeleteBankAccountMutation,
   useGetDefaultBankAccountQuery,
   useSetDefaultBankAccountMutation,
} = bankAccountsApi;
