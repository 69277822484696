import {ColumnConfig} from './column-config';
import Status from '../invoices-list-item/status';
import Amount from '../invoices-list-item/amount/amount';
import React from 'react';
import AccountingRemark from '../invoices-list-item/accounting-remark/accounting-remark';
import moment from 'moment';
import BankAccountCell from './bank-account-cell';
import VatCodeCell from './vat-code-cell';
import AccountingNumberCell from './accounting-number-cell';
import CostCenterCell from './cost-center-cell';


const addInvoiceIdColumn = (hiddenColumns) => (
    new ColumnConfig('common:common.name', 'name', 'invoiceId', !hiddenColumns.includes('invoiceId'),
        (invoice, onClick) => (<td className='name'  onClick={onClick}>{invoice.invoiceId}</td>))
);

const addCompanyColumn = (hiddenColumns) => (
    new ColumnConfig('invoice:invoice.company', 'company', 'company', !hiddenColumns.includes('company'),
        (invoice, onClick) => (<td className={'company'} onClick={onClick}>{invoice.company}</td>))
);

const addStatusColumn = (hiddenColumns) => (
    new ColumnConfig('common:common.status.label', 'status', 'status',
        !hiddenColumns.includes('status'),
        (invoice, onClick) => (
            <td className={'status'}  onClick={onClick}>
              <Status approvals={invoice.approvals} rejections={invoice.rejections}/>
            </td>
        ))
);

const addAmountColumn = (hiddenColumns) => (
    new ColumnConfig('invoice:invoice.amount', 'amount', 'amount',
        !hiddenColumns.includes('amount'),
        invoice => (<td className={'amount'}><Amount invoice={invoice}/></td>))
);

const addCurrencyColumn = (hiddenColumns) => (
    new ColumnConfig('invoice:invoice.currencyShort', 'currency', 'currency',
        !hiddenColumns.includes('currency'),
        (invoice, onClick) => (<td className={'currency'} onClick={onClick}>{invoice.currency}</td>))
);

const addBankAccountColumn = (hiddenColumns) => (
    new ColumnConfig('invoice:invoice.bankAccount', 'bank-account', 'bankAccount',
        !hiddenColumns.includes('bankAccount'),
        invoice => <BankAccountCell invoice={invoice}/> )
);

const addVatCodeColumn = (hiddenColumns) => (
    new ColumnConfig('invoice:invoice.vatCode', 'vat-code', 'vatId',
        !hiddenColumns.includes('vatCode'),
        invoice => <VatCodeCell invoice={invoice}/>)
);

const addAccountingNumberColumn = (hiddenColumns) => (
    new ColumnConfig('invoice:invoice.accountingNumber', 'accounting-number', 'accountingNumber',
        !hiddenColumns.includes('accountingNumber'),
        invoice => <AccountingNumberCell invoice={invoice}/>)
);

const addCostCenterColumn = (hiddenColumns) => (
    new ColumnConfig('invoice:invoice.costCenter', 'cost-center', 'costCenter',
        !hiddenColumns.includes('costCenter'),
        invoice => <CostCenterCell invoice={invoice}/>)
);

const addAccountingRemarkColumn = (hiddenColumns) => (
    new ColumnConfig('invoice:invoice.accountingRemark', 'accounting-remark', 'accountingRemark',
        !hiddenColumns.includes('accountingRemark'),
        invoice =>  <td className='accounting-remark'><AccountingRemark invoice={invoice}/></td>)
);

const addDescriptionColumn = (hiddenColumns) => (
    new ColumnConfig('invoice:invoice.description', 'description', 'description',
        !hiddenColumns.includes('description'),
        invoice => <td className={'description'}>{invoice.description}</td>)
);

const addGenerationDateColumn = (hiddenColumns) => (
    new ColumnConfig('invoice:invoice.generationDate', 'dta-date', 'generationDate',
        !hiddenColumns.includes('generationDate'),
        invoice => <td className={'dta-date'}>{invoice.dtaDate ? moment(invoice.dtaDate).format('DD-MM-YYYY') : ''}</td>)
);

const addPaymentDateColumn = (hiddenColumns) => (
    new ColumnConfig('invoice:invoice.paymentDate', 'payment-date', 'paymentDate',
        !hiddenColumns.includes('paymentDate'),
        (invoice, onClick) => <td className={'payment-date'} onClick={onClick}>{invoice.paymentDate ? moment(invoice.paymentDate).format('DD-MM-YYYY') : ''}</td>)
);


export const getColumnsConfig  = (hiddenColumns, costCentersExist: boolean): ColumnConfig[] => {
  const columns : ColumnConfig[] = [];

  columns.push(addInvoiceIdColumn(hiddenColumns));
  columns.push(addCompanyColumn(hiddenColumns));
  columns.push(addStatusColumn(hiddenColumns));
  columns.push(addAmountColumn(hiddenColumns));
  columns.push(addCurrencyColumn(hiddenColumns));
  columns.push(addBankAccountColumn(hiddenColumns));
  columns.push(addVatCodeColumn(hiddenColumns));
  columns.push(addAccountingNumberColumn(hiddenColumns));
  costCentersExist && columns.push(addCostCenterColumn(hiddenColumns));
  columns.push(addAccountingRemarkColumn(hiddenColumns));
  columns.push(addDescriptionColumn(hiddenColumns));
  columns.push(addGenerationDateColumn(hiddenColumns));
  columns.push(addPaymentDateColumn(hiddenColumns));

  return columns;
}








