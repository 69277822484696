import React from 'react';
import {Dropdown, Nav, Navbar} from 'react-bootstrap';
import { PersonCircle } from 'react-bootstrap-icons';
import './top-bar.scss';
import {Link, NavLink, useNavigate} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {useMsal} from '@azure/msal-react';
import { useKeycloak } from '@react-keycloak/web';
import { getAppName, isAzureIDP, isKeycloakIDP } from '../../../utils/loginProvider';

const TopBar = () => {
   const navigate = useNavigate();
   const useConditionalKeycloak = () => {
      if (isKeycloakIDP) {
         return useKeycloak();
      }
      return null;
   };

   const keycloakContext = useConditionalKeycloak();
   const keycloak = keycloakContext ? keycloakContext.keycloak : null;

   const [translate] = useTranslation(['common', 'invoice', 'account']);
   const { instance } = useMsal();

   const ProfileIcon = React.forwardRef<
      HTMLDivElement,
      { onClick: (e: React.MouseEvent<HTMLDivElement>) => void }
   >(({ onClick }, ref) => (
      <div
         ref={ref}
         onClick={(e) => {
            e.preventDefault();
            onClick(e);
         }}
      >
         <PersonCircle className={'cursor-pointer'} color={'white'} size={'30px'} />
      </div>
   ));

   const logoutFromApp = () => isAzureIDP ? azureLogout() : keycloakLogout();

   const azureLogout = () => {
      const logoutRequest = {
         postLogoutRedirectUri: '/',
         mainWindowRedirectUri: '/',
      };
      localStorage.removeItem('accessToken');
      instance.logoutRedirect(logoutRequest);
   }

   const keycloakLogout = () => keycloak && keycloak.logout();

   return (
      <>
         <Navbar className={'d-flex justify-content-between px-4'} bg='dark' variant='dark'>
            <Navbar.Brand className={'cursor-pointer'}>
              <Link className='link' to='/'>
                 {isKeycloakIDP ? 'Accountitz' : translate('app.appName')} {getAppName()}
              </Link>
            </Navbar.Brand>
           <Navbar.Collapse id="basic-navbar-nav">
             <Nav className="me-auto">
               <NavLink className={(navData) => (navData.isActive ? 'active' : 'link')} to='/'>
                 {translate('invoice:invoice.invoices')}
                </NavLink>
               <NavLink className={(navData) => (navData.isActive ? 'active' : 'link')} to='/bank-accounts'>
                 {translate('account:bankAccount.bankAccounts')}
               </NavLink>
             </Nav>
           </Navbar.Collapse>

            <Navbar>
               <Dropdown align='end'>
                  <Dropdown.Toggle as={ProfileIcon} />

                  <Dropdown.Menu variant={'dark'}>
                     <Dropdown.Item onClick={() => navigate('/profile')}>
                        {translate('profile.profile')}
                     </Dropdown.Item>
                     <Dropdown.Item
                        onClick={logoutFromApp}
                     >
                        {translate('common:profile:logout')}
                     </Dropdown.Item>
                  </Dropdown.Menu>
               </Dropdown>
            </Navbar>
         </Navbar>
      </>
   );
};

export default TopBar;
