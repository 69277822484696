import React from 'react';
import { useTranslation } from 'react-i18next';
import {InvoiceDto} from '../../../models/invoice.dto';
import {Dropdown} from 'react-bootstrap';
import {isEmptyArray} from '../../../utils/base';
import {
    useApproveInvoicesMutation, useDownloadInvoiceMutation,
    useDownloadInvoicesMutation
} from '../../../store/invoices/invoices.api';

interface PropsType {
    selectedInvoices: InvoiceDto[],
    resetSelectedInvoices: () => void
}

const ActionDropdown = ({
    selectedInvoices, resetSelectedInvoices
}: PropsType) => {
    const [approveInvoices] = useApproveInvoicesMutation();
    const [downloadInvoices] = useDownloadInvoicesMutation();
    const [downloadInvoice] = useDownloadInvoiceMutation();
    const [translate] = useTranslation(['common']);

    const isEmptySelectedInvoices = isEmptyArray(selectedInvoices);

    const getInvoiceIds = () => selectedInvoices.map(invoice => invoice.invoiceId);

    const approveSelectedInvoices = () => approveInvoices(getInvoiceIds()).then(() => resetSelectedInvoices());

    const downloadSelectedInvoices = () => {
        const oneFileToDownload = selectedInvoices && selectedInvoices.length === 1;

        oneFileToDownload
            ? downloadInvoice({ invoiceId: selectedInvoices[0].invoiceId }).then(() => resetSelectedInvoices())
            : downloadInvoices(getInvoiceIds()).then(() => resetSelectedInvoices());
    };

    return (
        <Dropdown>
            <Dropdown.Toggle variant="success" id="dropdown-action">
                {translate('common:common.actions')}
            </Dropdown.Toggle>

            <Dropdown.Menu>
                <Dropdown.Item
                    disabled={isEmptySelectedInvoices}
                    onClick={approveSelectedInvoices}
                >
                    {translate('common:common.approveAll')}
                </Dropdown.Item>
                <Dropdown.Item
                    disabled={isEmptySelectedInvoices}
                    onClick={downloadSelectedInvoices}
                >
                    {translate('common:common.downloadAll')}
                </Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    );
};

export default ActionDropdown;
