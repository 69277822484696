import {Form} from 'react-bootstrap';
import {InvoiceDto} from '../../../../models/invoice.dto';
import './amount.scss';
import {useEffect, useRef, useState} from 'react';
import ConfirmationDialog from './confirmation-dialog';
import {useUpdateInvoiceMutation} from '../../../../store/invoices/invoices.api';

interface Props {
    invoice: InvoiceDto;
}

function format(amount: number) {
    return (Math.round(amount * 100) / 100).toFixed(2);
}

const Amount = ({invoice}: Props) => {
    const currentAmount = format(invoice.amount);
    const [amount, setAmount] = useState(currentAmount);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [updateInvoice] = useUpdateInvoiceMutation();
    const readOnly = invoice.amount != 0 && invoice.rejections.filter(r => r.active).length == 0;
    const className = readOnly ? 'amount' : 'amountEditable';
    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        setAmount(format(invoice.amount));
    }, [invoice]);

    function isAmountChanged() {
        return parseFloat(amount) != parseFloat(currentAmount);
    }

    const onKeyDown = event => {
        if (event.key === 'Enter' && isAmountChanged()) {
            setShowConfirmation(true);
        }
    };

    const onBlur = () => {
        if (isAmountChanged()) {
            setShowConfirmation(true)
        }
    };

    return <>
        <Form.Control
            onKeyDown={onKeyDown}
            onBlur={onBlur}
            onChange={e => setAmount(e.target.value)}
            plaintext
            readOnly={readOnly}
            value={amount}
            ref={inputRef}
            className={className}/>
        <ConfirmationDialog
            show={showConfirmation}
            onHide={() => {
                setShowConfirmation(false);
                setAmount(currentAmount);
            }}
            onConfirm={() => {
                updateInvoice({ invoiceId: invoice.invoiceId, data: { ...invoice, amount: parseFloat(amount) } });
                setShowConfirmation(false);
            }}

            currentAmount={currentAmount}
            newAmount={amount}
        />
    </>;
}

export default Amount;