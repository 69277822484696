import {Button, Modal} from 'react-bootstrap';
import React from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {useDeleteInvoiceMutation} from '../../../../store/invoices/invoices.api';
import './delete-dialog.scss'

interface Props {
  show: boolean,
  onHide: () => void,
  invoiceId: number;
  invoiceName: string;
}

const DeleteDialog = ({show, onHide, invoiceId, invoiceName}: Props) => {
  const [translate] = useTranslation(['account', 'common', 'invoice']);
  const [deleteInvoice] = useDeleteInvoiceMutation();

  const onDelete = () => {
    deleteInvoice({invoiceId});
    onHide();
  };
  return (
    <Modal show={show} onHide={onHide} dialogClassName='delete-dialog'>
        <Modal.Header>
          <Modal.Title>{translate('common:common.delete')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Trans i18nKey="invoice:invoice.delete"
                 tOptions={{invoiceName}}/>
        </Modal.Body>
        <Modal.Footer>

          <Button variant='secondary' onClick={onHide}>
            {translate('common:common.cancel')}
          </Button>
          <Button variant='primary' onClick={onDelete}>
            {translate('common:common:ok')}
          </Button>
        </Modal.Footer>
    </Modal>
  );
};

export default DeleteDialog;