import {CountryDto} from '../../../models/country.dto';

export const countries = [
    new CountryDto('AF'),
    new CountryDto('AL'),
    new CountryDto('DZ'),
    new CountryDto('AS'),
    new CountryDto('AD'),
    new CountryDto('AO'),
    new CountryDto('AI'),
    new CountryDto('AQ'),
    new CountryDto('AG'),
    new CountryDto('AR'),
    new CountryDto('AM'),
    new CountryDto('AW'),
    new CountryDto('AU'),
    new CountryDto('AT'),
    new CountryDto('AZ'),
    new CountryDto('BS'),
    new CountryDto('BH'),
    new CountryDto('BD'),
    new CountryDto('BB'),
    new CountryDto('BY'),
    new CountryDto('BE'),
    new CountryDto('BZ'),
    new CountryDto('BJ'),
    new CountryDto('BM'),
    new CountryDto('BT'),
    new CountryDto('BO'),
    new CountryDto('BQ'),
    new CountryDto('BA'),
    new CountryDto('BW'),
    new CountryDto('BV'),
    new CountryDto('BR'),
    new CountryDto('IO'),
    new CountryDto('BN'),
    new CountryDto('BG'),
    new CountryDto('BF'),
    new CountryDto('BI'),
    new CountryDto('CV'),
    new CountryDto('KH'),
    new CountryDto('CM'),
    new CountryDto('CA'),
    new CountryDto('KY'),
    new CountryDto('CF'),
    new CountryDto('TD'),
    new CountryDto('CL'),
    new CountryDto('CN'),
    new CountryDto('CX'),
    new CountryDto('CC'),
    new CountryDto('CO'),
    new CountryDto('KM'),
    new CountryDto('CD'),
    new CountryDto('CG'),
    new CountryDto('CK'),
    new CountryDto('CR'),
    new CountryDto('HR'),
    new CountryDto('CU'),
    new CountryDto('CW'),
    new CountryDto('CY'),
    new CountryDto('CZ'),
    new CountryDto('CI'),
    new CountryDto('DK'),
    new CountryDto('DJ'),
    new CountryDto('DM'),
    new CountryDto('DO'),
    new CountryDto('EC'),
    new CountryDto('EG'),
    new CountryDto('SV'),
    new CountryDto('GQ'),
    new CountryDto('ER'),
    new CountryDto('EE'),
    new CountryDto('SZ'),
    new CountryDto('ET'),
    new CountryDto('FK'),
    new CountryDto('FO'),
    new CountryDto('FJ'),
    new CountryDto('FI'),
    new CountryDto('FR'),
    new CountryDto('GF'),
    new CountryDto('PF'),
    new CountryDto('TF'),
    new CountryDto('GA'),
    new CountryDto('GM'),
    new CountryDto('GE'),
    new CountryDto('DE'),
    new CountryDto('GH'),
    new CountryDto('GI'),
    new CountryDto('GR'),
    new CountryDto('GL'),
    new CountryDto('GD'),
    new CountryDto('GP'),
    new CountryDto('GU'),
    new CountryDto('GT'),
    new CountryDto('GG'),
    new CountryDto('GN'),
    new CountryDto('GW'),
    new CountryDto('GY'),
    new CountryDto('HT'),
    new CountryDto('HM'),
    new CountryDto('VA'),
    new CountryDto('HN'),
    new CountryDto('HK'),
    new CountryDto('HU'),
    new CountryDto('IS'),
    new CountryDto('IN'),
    new CountryDto('ID'),
    new CountryDto('IR'),
    new CountryDto('IQ'),
    new CountryDto('IE'),
    new CountryDto('IM'),
    new CountryDto('IL'),
    new CountryDto('IT'),
    new CountryDto('JM'),
    new CountryDto('JP'),
    new CountryDto('JE'),
    new CountryDto('JO'),
    new CountryDto('KZ'),
    new CountryDto('KE'),
    new CountryDto('KI'),
    new CountryDto('KP'),
    new CountryDto('KR'),
    new CountryDto('KW'),
    new CountryDto('KG'),
    new CountryDto('LA'),
    new CountryDto('LV'),
    new CountryDto('LB'),
    new CountryDto('LS'),
    new CountryDto('LR'),
    new CountryDto('LY'),
    new CountryDto('LI'),
    new CountryDto('LT'),
    new CountryDto('LU'),
    new CountryDto('MO'),
    new CountryDto('MG'),
    new CountryDto('MW'),
    new CountryDto('MY'),
    new CountryDto('MV'),
    new CountryDto('ML'),
    new CountryDto('MT'),
    new CountryDto('MH'),
    new CountryDto('MQ'),
    new CountryDto('MR'),
    new CountryDto('MU'),
    new CountryDto('YT'),
    new CountryDto('MX'),
    new CountryDto('FM'),
    new CountryDto('MD'),
    new CountryDto('MC'),
    new CountryDto('MN'),
    new CountryDto('ME'),
    new CountryDto('MS'),
    new CountryDto('MA'),
    new CountryDto('MZ'),
    new CountryDto('MM'),
    new CountryDto('NA'),
    new CountryDto('NR'),
    new CountryDto('NP'),
    new CountryDto('NL'),
    new CountryDto('NC'),
    new CountryDto('NZ'),
    new CountryDto('NI'),
    new CountryDto('NE'),
    new CountryDto('NG'),
    new CountryDto('NU'),
    new CountryDto('NF'),
    new CountryDto('MP'),
    new CountryDto('NO'),
    new CountryDto('OM'),
    new CountryDto('PK'),
    new CountryDto('PW'),
    new CountryDto('PS'),
    new CountryDto('PA'),
    new CountryDto('PG'),
    new CountryDto('PY'),
    new CountryDto('PE'),
    new CountryDto('PH'),
    new CountryDto('PN'),
    new CountryDto('PL'),
    new CountryDto('PT'),
    new CountryDto('PR'),
    new CountryDto('QA'),
    new CountryDto('MK'),
    new CountryDto('RO'),
    new CountryDto('RU'),
    new CountryDto('RW'),
    new CountryDto('RE'),
    new CountryDto('BL'),
    new CountryDto('SH'),
    new CountryDto('KN'),
    new CountryDto('LC'),
    new CountryDto('MF'),
    new CountryDto('PM'),
    new CountryDto('VC'),
    new CountryDto('WS'),
    new CountryDto('SM'),
    new CountryDto('ST'),
    new CountryDto('SA'),
    new CountryDto('SN'),
    new CountryDto('RS'),
    new CountryDto('SC'),
    new CountryDto('SL'),
    new CountryDto('SG'),
    new CountryDto('SX'),
    new CountryDto('SK'),
    new CountryDto('SI'),
    new CountryDto('SB'),
    new CountryDto('SO'),
    new CountryDto('ZA'),
    new CountryDto('GS'),
    new CountryDto('SS'),
    new CountryDto('ES'),
    new CountryDto('LK'),
    new CountryDto('SD'),
    new CountryDto('SR'),
    new CountryDto('SJ'),
    new CountryDto('SE'),
    new CountryDto('CH'),
    new CountryDto('SY'),
    new CountryDto('TW'),
    new CountryDto('TJ'),
    new CountryDto('TZ'),
    new CountryDto('TH'),
    new CountryDto('TL'),
    new CountryDto('TG'),
    new CountryDto('TK'),
    new CountryDto('TO'),
    new CountryDto('TT'),
    new CountryDto('TN'),
    new CountryDto('TR'),
    new CountryDto('TM'),
    new CountryDto('TC'),
    new CountryDto('TV'),
    new CountryDto('UG'),
    new CountryDto('UA'),
    new CountryDto('AE'),
    new CountryDto('GB'),
    new CountryDto('UM'),
    new CountryDto('US'),
    new CountryDto('UY'),
    new CountryDto('UZ'),
    new CountryDto('VU'),
    new CountryDto('VE'),
    new CountryDto('VN'),
    new CountryDto('VG'),
    new CountryDto('VI'),
    new CountryDto('WF'),
    new CountryDto('EH'),
    new CountryDto('YE'),
    new CountryDto('ZM'),
    new CountryDto('AX'),
    new CountryDto('ZW'),
];