import { createApi } from '@reduxjs/toolkit/query/react';
import { VatCodeDto } from '../../models/vat-code.dto';
import { baseQueryWithRedirect } from '../base-query-with-redirect';

export const vatCodesApi = createApi({
  reducerPath: 'vatCodes/api',
  tagTypes: ['VatCodes'],
  baseQuery: baseQueryWithRedirect,
  endpoints: (build) => ({
    getVatCodes: build.query<VatCodeDto[], void>({
      query: () => ({
        url: 'vat',
      }),
      providesTags: ['VatCodes'],
    }),
  }),
});

export const { useGetVatCodesQuery } = vatCodesApi;
