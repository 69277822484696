import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {loginRequest} from '../../../config/auth-config';
import {useMsal} from '@azure/msal-react';
import {Button} from 'react-bootstrap';
import './login.scss';
import {useNavigate} from 'react-router-dom';
import { setAccessTokenLocalStorage } from '../../../utils/token';
import { getAppName } from '../../../utils/loginProvider';

const Login = () => {
  const {instance} = useMsal();
  const navigate = useNavigate();
  const login = () => {
    instance.loginRedirect(loginRequest)
  }

  const setAccessToken = e => {
     setAccessTokenLocalStorage(e.accessToken)
      navigate('/')
  };

  useEffect(() => {

    const silentRequest = {...loginRequest, account: instance.getAllAccounts()[0]};
     const acquireTokenSilentAndSetAccessToken = () => {
        instance
           .acquireTokenSilent(silentRequest)
           .then(setAccessToken)
           .catch(() => {
              setTimeout(() => {
                 instance
                    .acquireTokenSilent(silentRequest)
                    .then(setAccessToken);
              }, 500)
           });
     };

     const accounts = instance.getAllAccounts();
     if (accounts.length > 0) {
        acquireTokenSilentAndSetAccessToken();
     }
  }, [])

  const [translate] = useTranslation('common');

  return (
    <div className='root'>
      <h1 className='label'>{translate('common:login.singIn')} {getAppName()}</h1>
      <Button className='login' onClick={login}>{translate('common:login.signInWith')} <b>Microsoft</b></Button>
    </div>
  );
};

export default Login;
