import { ChangeEvent, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { LanguageCodes } from '../../config/enums/Language';
import { useUpdateUserDataMutation } from '../../store/user/user.api';
import { UserDto } from '../../models/user.dto';

interface LanguageSwitcherProps {
   userData: UserDto
}

const LanguageSwitcher: FC<LanguageSwitcherProps> = ({
   userData
}) => {
   const [updateUserData] = useUpdateUserDataMutation();
   const { i18n } = useTranslation();

   const onLanguageChange = (e: ChangeEvent<HTMLSelectElement>) => {
      const newLanguage = e.target.value;
      const updatedUserDto: UserDto = { ...userData, language: newLanguage };
      updateUserData(updatedUserDto);
      i18n.changeLanguage(newLanguage);
   }

   return (
      <div className='select'>
         <select
            value={i18n.language}
            onChange={onLanguageChange}
         >
            <option value={LanguageCodes.ENGLISH}>English</option>
            <option value={LanguageCodes.DEUTSCH}>Deutsch</option>
         </select>
      </div>
   );
}
export default LanguageSwitcher;
