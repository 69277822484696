import { InvoiceDto } from '../../models/invoice.dto';
import Pluralize from 'pluralize';
import { groupBy } from '../../utils/groupBy';
import DatePicker from 'react-datepicker';
import { useTranslation } from 'react-i18next';

interface PropsType {
   selectedInvoices: InvoiceDto[];
   paymentDate: Date;
   setPaymentDate: (paymentDate: Date) => void;
}

const ExportModal = ({ selectedInvoices, paymentDate, setPaymentDate }: PropsType) => {
   const [translate] = useTranslation('invoice');

   return (
      <div>
         <div>
            {translate('invoice.exportInvoicesListInfo')}
            {Object.entries(groupBy(selectedInvoices, (i) => i.bankAccount.name)).map(
               (invoicesGroup: [string, InvoiceDto[]]) => {
                  return (
                     <div key={invoicesGroup[0]}>
                        {invoicesGroup[0]}: <b>{invoicesGroup[1].length}</b>{' '}
                        {Pluralize('invoice', invoicesGroup[1].length)}
                     </div>
                  );
               },
            )}
         </div>

         <br></br>

         <div>
            Payment date:
            <DatePicker
               className={'w-100'}
               selected={paymentDate}
               dateFormat='dd/MM/yyyy'
               onKeyDown={(e) => e.preventDefault()}
               onChange={(date: Date) => {
                  setPaymentDate(date);
                  paymentDate = date;
               }}
            />
         </div>
      </div>
   );
};

export default ExportModal;
