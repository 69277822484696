import React, { useEffect, useRef, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import FilterBar from './filter-search/filter-bar';
import './search-bar.scss';
import { useDebounce } from 'usehooks-ts';
import { useTranslation } from 'react-i18next';
import { AccountingNumberDto } from '../../../models/accounting-number.dto';
import { CostCenterDto } from '../../../models/cost-center.dto';
import { ArrowDownSquareFill, ArrowUpSquareFill } from 'react-bootstrap-icons';
import {
   INVOICE_GENERATION_FILER_ALL,
   INVOICE_GENERATION_FILER_GENERATED,
   INVOICE_GENERATION_FILER_PENDING, INVOICE_PAGINATION_DEFAULT_SIZE,
   InvoiceGenerationFilters,
} from '../../../models/invoice.dto';

interface PropsType {
   onSearch: (
      name: string,
      fromDate: Date,
      toDate: Date,
      fromAmount: string,
      toAmount: string,
      costCenter: string,
      accountingNumber: string,
      areExportedInvoicesShown: InvoiceGenerationFilters,
      page: number,
      size: number
   ) => void;
   searchParams;
   accountingNumbers: AccountingNumberDto[];
   costCenters: CostCenterDto[];
}

const SearchBar = ({ onSearch, searchParams, accountingNumbers, costCenters }: PropsType) => {
   const [translate] = useTranslation(['common']);
   const searchPlaceholder = translate('search.searchHere');

   const [fromDate, setFromDate] = useState<Date>(new Date(searchParams.fromDate));
   const [toDate, setToDate] = useState<Date>(new Date(searchParams.toDate));
   const [fromAmount, setFromAmount] = useState<string>(searchParams.fromAmount);
   const [toAmount, setToAmount] = useState<string>(searchParams.toAmount);
   const [name, setName] = useState<string>(searchParams.name);
   const [accountingNumber, setAccountingNumber] = useState<string>(
      searchParams.accountingNumberValue,
   );
   const [costCenter, setCostCenter] = useState<string>(searchParams.costCenterValue);
   const [generationDateFilter, setGenerationDateFilter]
     = useState<InvoiceGenerationFilters>(INVOICE_GENERATION_FILER_PENDING);

   const debouncedFromAmount = useDebounce<string>(fromAmount, 500);
   const debouncedToAmount = useDebounce<string>(toAmount, 500);
   const debouncedName = useDebounce<string>(name, 500);

   const [hideFilter, setHideFilter] = useState<boolean>(false);
   const isInitialMount = useRef(true);

   useEffect(() => {
      if (isInitialMount.current) {
         isInitialMount.current = false;
      } else {
         onSearch(
             debouncedName,
             fromDate,
             toDate,
             fromAmount,
             toAmount,
             costCenter,
             accountingNumber,
             generationDateFilter,
             0,
             INVOICE_PAGINATION_DEFAULT_SIZE
         );
      }
   }, [
      debouncedName,
      fromDate,
      toDate,
      debouncedFromAmount,
      debouncedToAmount,
      costCenter,
      accountingNumber,
      generationDateFilter,
   ]);

   const onChangeFilterBar = (
      fromDate,
      toDate,
      fromAmount,
      toAmount,
      accountingNumber,
      costCenter,
   ) => {
      setFromDate(fromDate);
      setToDate(toDate);
      setFromAmount(fromAmount);
      setToAmount(toAmount);
      setAccountingNumber(accountingNumber);
      setCostCenter(costCenter);
   };

   function resetFilterValues(): void {
      setFromDate(new Date(searchParams.fromDate));
      setToDate(new Date(searchParams.toDate));
      setFromAmount(searchParams.fromAmount);
      setToAmount(searchParams.toAmount);
      setAccountingNumber(searchParams.accountingNumberValue);
      setCostCenter(searchParams.accountingNumberValue);
      setName(searchParams.name);
   }


   return (
      <div>
         <div className='search-block'>
            <Button
               className={'mb-1 mx-3 top name'}
               onClick={() => setGenerationDateFilter(INVOICE_GENERATION_FILER_PENDING)}
               variant={generationDateFilter === INVOICE_GENERATION_FILER_PENDING ? 'dark' : 'light'}
            >
               {translate('common:common.unpaid')}
            </Button>
            <Button
               className={'mb-1 mx-3 top name'}
               onClick={() => setGenerationDateFilter(INVOICE_GENERATION_FILER_GENERATED)}
               variant={generationDateFilter === INVOICE_GENERATION_FILER_GENERATED ? 'dark' : 'light'}
            >
               {translate('common:common.paid')}
            </Button>
            <Button
               className={'mb-1 mx-3 top name'}
               onClick={() => setGenerationDateFilter(INVOICE_GENERATION_FILER_ALL)}
               variant={generationDateFilter === INVOICE_GENERATION_FILER_ALL ? 'dark' : 'light'}
            >
               {translate('common:common.all')}
            </Button>

            <Form
               className={'mx-3'}
               onSubmit={(e) => {
                  e.preventDefault();
               }}
            >
               <Form.Control
                  type='search'
                  value={name}
                  placeholder={searchPlaceholder}
                  onChange={(e) => {
                     setName(e?.target.value);
                  }}
               />
            </Form>

            {hideFilter ? (
               <ArrowUpSquareFill
                  className={'cursor-pointer'}
                  size={'38px'}
                  onClick={() => {
                     setHideFilter(false);
                  }}
               />
            ) : (
               <ArrowDownSquareFill
                  className={'cursor-pointer'}
                  size={'38px'}
                  onClick={() => {
                     setHideFilter(true);
                  }}
               />
            )}

            <Button
               className={`mb-1 content-margin ${hideFilter ? '' : 'd-none'}`}
               onClick={() => resetFilterValues()}
               variant='dark'
            >
               {translate('common:search.clearAllFilters')}
            </Button>
         </div>

         <div id='filter' className={`box ${hideFilter ? '' : 'd-none'} w-100`}>
            <FilterBar
               fromDate={fromDate}
               toDate={toDate}
               fromAmount={fromAmount}
               toAmount={toAmount}
               costCenterValue={costCenter}
               accountingNumberValue={accountingNumber}
               onChangeFilterBar={onChangeFilterBar}
               accountingNumbers={accountingNumbers}
               costCenters={costCenters}
            />
         </div>
      </div>
   );
};

export default SearchBar;
