import {LanguageCodes} from '../config/enums/Language';

const langItemName = 'qrcode-lang';

export const setLanguageToLocalStorage = (lang: string) => {
    localStorage.setItem(langItemName, lang);
}

export const getLanguageFromLocalStorage = (): string => {
    return localStorage.getItem(langItemName) || LanguageCodes.DEFAULT;
}