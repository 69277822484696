import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './app';
import {BrowserRouter} from 'react-router-dom';
import {Provider} from 'react-redux';
import {store} from './store';
import './services/i18n';
import {PublicClientApplication} from '@azure/msal-browser';
import {MsalProvider} from '@azure/msal-react';
import {msalConfig} from './config/auth-config';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import { initOptions, keycloakClient } from './config/keycloak/Keycloak';
import KeycloakAppRoutes from './config/routes/KeycloakAppRoutes';
import { isAzureIDP } from './utils/loginProvider';


const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
   isAzureIDP ? (
      <MsalProvider instance={new PublicClientApplication(msalConfig)}>
         <Provider store={store}>
            <BrowserRouter>
               <App />
            </BrowserRouter>
         </Provider>
      </MsalProvider>
   ) : (
      <Provider store={store}>
         <ReactKeycloakProvider
            authClient={keycloakClient}
            initOptions={initOptions}
         >
            <Suspense fallback={<div></div>}>
               <KeycloakAppRoutes />
            </Suspense>
         </ReactKeycloakProvider>
      </Provider>
   ),
);
