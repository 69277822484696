import React, { useEffect } from 'react';
import InvoicesList from '../invoices-list/invoices-list';
import { useGetBankAccountsQuery } from '../../store/bank-accounts/bank-accounts.api';
import {
   useGetAccountingNumbersQuery,
   useLazyGetAccountingNumbersInUseQuery,
} from '../../store/accounting-numbers/accounting-numbers.api';
import {
   useGetCostCentersQuery,
   useLazyGetCostCentersInUseQuery,
} from '../../store/cost-centers/cost-centers.api';
import {useGetSettingsQuery} from '../../store/settings/settings.api';

const Dashboard = () => {
   const { data: bankAccounts } = useGetBankAccountsQuery();
   const { data: costCenters } = useGetCostCentersQuery();
   const { data: settings } = useGetSettingsQuery();
   const [getAccountNumbersInUse, { data: accountingNumbersInUse }] =
      useLazyGetAccountingNumbersInUseQuery();
   const [getCostCentersInUse, { data: costCentersInUse }] = useLazyGetCostCentersInUseQuery();
   const { data: accountingNumbers } = useGetAccountingNumbersQuery();

   useEffect(() => {
      handleInvoiceNumberOrCenterUpdate();
   }, []);

   const handleInvoiceNumberOrCenterUpdate = () => {
      getAccountNumbersInUse();
      getCostCentersInUse();
   };

   return (
      <>
         <div className={'m-3'}>
            {
               bankAccounts &&
               accountingNumbers &&
               costCenters &&
               accountingNumbersInUse &&
               costCentersInUse &&
               settings &&
              (
                  <InvoicesList
                     handleInvoiceNumberOrCenterUpdate={handleInvoiceNumberOrCenterUpdate}
                     accountingNumbersInUse={accountingNumbersInUse}
                     costCentersInUse={costCentersInUse}
                     costCenters={costCenters}
                     accountingNumbers={accountingNumbers}
                  />
               )}
         </div>
      </>
   );
};
export default Dashboard;
