import { FC, ReactElement, useEffect } from 'react';
import { useKeycloak } from '@react-keycloak/web';
import {Navigate} from 'react-router-dom';

interface ProtectedProps {
   redirectTo?: string,
   component: ReactElement
}

export const Protected: FC<ProtectedProps> = ({
   redirectTo = '/',
   component
}) => {
   const { keycloak } = useKeycloak();

   const loggedIn = keycloak.authenticated;

   if (keycloak) {
      keycloak.onTokenExpired = () => keycloak.updateToken(9000); // todo: reconfigure setup token expire time in keycloak
   }

   useEffect(() => {
      return () => {
         if (keycloak) keycloak.onTokenExpired = () => { return; };
      };
   }, [keycloak]);


   if (!loggedIn) {
      keycloak.login();
      return <></>;
   }

   if (!component) {
      return <Navigate to={redirectTo} replace />;
   }

   return <>{component}</>;
};