import React from 'react';
import { useTranslation } from 'react-i18next';
import './profile.scss';
import { useGetUserDataQuery } from '../../../store/user/user.api';
import ProfileInfo from '../../profile/profile-info';

const Profile = () => {
   const [translate] = useTranslation(['account', 'common']);
   const { data: userData } = useGetUserDataQuery();

   return (
      <div className={'m-3'}>
         <h3 className={'mb-3'}>{translate('common:profile.profile')}</h3>
          {userData && <ProfileInfo userData={userData}></ProfileInfo>}
      </div>
   );
};

export default Profile;
