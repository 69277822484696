import {InvoiceDto} from '../../../models/invoice.dto';
import {useGetBankAccountsQuery} from '../../../store/bank-accounts/bank-accounts.api';
import {useUpdateInvoiceMutation} from '../../../store/invoices/invoices.api';
import {useTranslation} from 'react-i18next';
import {BankAccountDto} from '../../../models/bank-account.dto';
import DropdownWithFilter from '../../elements/dropdown-with-filter/dropdown-with-filter';
import React from 'react';

const BankAccountCell = ({invoice}: {invoice: InvoiceDto}) => {
  const { data: bankAccounts } = useGetBankAccountsQuery();
  const [updateInvoice] = useUpdateInvoiceMutation();
  const [translate] = useTranslation(['account', 'common', 'invoice']);

  const handleAccountChange = (bankAccount: BankAccountDto, invoice: InvoiceDto) => {
    updateInvoice({invoiceId: invoice.invoiceId, data: {...invoice, bankAccount}});
  };
  const handleBankAccountSelection = (account: BankAccountDto) => {
    handleAccountChange(account, invoice);
  };
  return (
    <td className={'filter-dropdown bank-account'}>
      {invoice.bankAccount ? (
        <DropdownWithFilter

          className='toggle'
          items={bankAccounts || []}
          selectedValue={`${invoice.bankAccount['name']} (${invoice.bankAccount['currency']})`}
          getMenuItemValue={(bankAccount: BankAccountDto) =>
            `${bankAccount['name']} (${bankAccount['currency']})`
          }
          handleChange={handleBankAccountSelection}
          handleFilter={(bankAccounts, filterValue) =>
            bankAccounts.filter((account) =>
              account.name.toLowerCase().includes(filterValue.toLowerCase()),
            )
          }
        ></DropdownWithFilter>
      ) : (
        <span>{translate('account:bankAccount.createBankAccount')}</span>
      )}
    </td>
  );
}

export default BankAccountCell;