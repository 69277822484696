import {Button, Form, Modal} from 'react-bootstrap';
import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useForm} from 'react-hook-form';
import './reject-dialog.scss';
import Required from '../../../elements/required';
import {useRejectInvoiceMutation} from '../../../../store/invoices/invoices.api';

interface Props {
    show: boolean,
    onHide: () => void,
    invoiceId: number;
}

const RejectDialog = ({show, onHide, invoiceId}: Props) => {
    const [translate] = useTranslation(['account', 'common', 'invoice']);
    const [rejectInvoice] = useRejectInvoiceMutation();
    const {register, reset, handleSubmit, formState: {errors, isValid}} = useForm({
        defaultValues: {reason: ''},
        mode: 'all'
    });
    useEffect(reset, [show])

    return (
        <Modal show={show} onHide={onHide} dialogClassName='reject-dialog'>
            <Form onSubmit={handleSubmit(data => {
                rejectInvoice({invoiceId: invoiceId, reason: data.reason})
                onHide();
            })}
            >
                <Modal.Header>
                    <Modal.Title>{translate('invoice:invoice.reject')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Label>{translate('invoice:invoice.rejectionReason')}<Required/></Form.Label>
                    <Form.Control
                        {...register('reason', {
                            required: translate('invoice:invoice.validation.required') || '',
                            maxLength: {value: 200, message: translate('invoice:invoice.validation.maxLength')}
                        })}
                        placeholder={translate('invoice:invoice.rejectionReasonPlaceholder') || ''}
                        isInvalid={!!errors.reason?.message}/>
                    <Form.Control.Feedback type="invalid">{errors.reason?.message}</Form.Control.Feedback>
                </Modal.Body>
                <Modal.Footer>

                    <Button variant='secondary' onClick={onHide}>
                        {translate('common:common.cancel')}
                    </Button>
                    <Button variant='primary' type='submit' disabled={!isValid}>
                        {translate('common:common:ok')}
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
};

export default RejectDialog;