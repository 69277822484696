import React, {ReactElement, useEffect, useRef, useState} from 'react';
import {Dropdown, Form, OverlayTrigger, Tooltip} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import './dropdown-with-filter.scss';
import {Noop} from 'react-hook-form';

interface PropsType {
   items: any[];
   selectedValue: string;
   getMenuItemValue: (item: any) => string;
   handleChange: (item: any) => void;
   handleFilter: (items: any[], filterValue: string) => any[];
   tooltip?: string;
   additionalMenuBottomElement?: ReactElement;
   colorMode?: 'light' | 'dark';
   className?: string;
   onBlur?: Noop;
}

const DropdownWithFilter = ({
   items,
   selectedValue,
   getMenuItemValue,
   handleChange,
   handleFilter,
   tooltip = '',
   additionalMenuBottomElement,
   className,
   onBlur,
}: PropsType) => {
   const [filterValue, setFilterValue] = useState('');
   const [filteredArr, setFilteredArr] = useState<any[]>([]);
   const [translate] = useTranslation(['common', 'invoice', 'account']);
   const listRef = useRef<HTMLDivElement>(null);
   const searchRef = useRef<HTMLInputElement>(null);

   useEffect(() => {
      setFilteredArr(handleFilter(items, filterValue));
   }, [filterValue, items]);
   const toggle = (
      <Dropdown.Toggle className={className}>
         {selectedValue ? selectedValue : translate('common:common.pleaseSelect')}
      </Dropdown.Toggle>
   );

   const searchOnKeyDown = event => {
      if (event.key === 'ArrowDown') {
         setTimeout(() => (listRef.current?.firstElementChild as HTMLAnchorElement).focus(), 0);
      }
      if (event.key === 'Enter' && filteredArr.length === 1) {
         setTimeout(() => document.body.click(), 0);
         handleChange(filteredArr[0]);
      }
   };

   const itemOnKeyDown = event => {
      if (event.key === 'ArrowUp') {
         setTimeout(() => searchRef.current?.focus(), 0);
      }
   }

   return (
      <Dropdown onBlur={onBlur}>
         <div>
            {tooltip ? (
               <OverlayTrigger key={'top'} placement={'top'} overlay={<Tooltip>{tooltip}</Tooltip>}>
                  {toggle}
               </OverlayTrigger>
            ) : (
               toggle
            )}
         </div>
         <Dropdown.Menu className={'px-1 dropdown-filter-menu'}>
            <Form.Control
               onKeyDown={searchOnKeyDown}
               autoFocus
               className={'mb-2'}
               type='text'
               value={filterValue}
               placeholder={translate('invoice:invoice.enterFilterValue')!}
               onChange={(event) => setFilterValue(event.target.value)}
               ref={searchRef}
            />
            <div className={'items-list'} ref={listRef}>
               {filteredArr.map((item, index) => (
                  <Dropdown.Item onClick={() => handleChange(item)}
                                 onKeyDown={index === 0 ? itemOnKeyDown : undefined}
                                 key={index}>
                     {getMenuItemValue(item)}
                  </Dropdown.Item>
               ))}
            </div>
            {additionalMenuBottomElement && (
               <Dropdown.Item className={'mt-3'}>{additionalMenuBottomElement}</Dropdown.Item>
            )}
         </Dropdown.Menu>
      </Dropdown>
   );
};

export default DropdownWithFilter;
