import {InvoiceDto} from '../../../models/invoice.dto';
import {useUpdateInvoiceMutation} from '../../../store/invoices/invoices.api';
import {useGetCostCentersQuery, useLazyGetCostCentersInUseQuery} from '../../../store/cost-centers/cost-centers.api';
import {CostCenterDto} from '../../../models/cost-center.dto';
import DropdownWithFilter from '../../elements/dropdown-with-filter/dropdown-with-filter';
import React from 'react';

const CostCenter = ({invoice}: {invoice: InvoiceDto}) => {
  const [updateInvoice] = useUpdateInvoiceMutation();
  const [getCostCentersInUse] = useLazyGetCostCentersInUseQuery();
  const { data: costCenters } = useGetCostCentersQuery();
  const selectedCostCenter = costCenters?.find(
    (center) => invoice.costCenter === center.costCenter,
  );

  const handleInvoiceCenterUpdate = () => {
    getCostCentersInUse();
  }

  const handleCostCenterChange = (costCenter: string, invoice: InvoiceDto) => {
    updateInvoice({invoiceId: invoice.invoiceId, data: {...invoice, costCenter}}).then(
      handleInvoiceCenterUpdate,
    );
  };
  const handleCostCenterSelection = (costCenter: CostCenterDto) => {
    handleCostCenterChange(costCenter.costCenter, invoice);
  };


  return (
    <td className={'filter-dropdown cost-center'}>
      <DropdownWithFilter

        className={ `toggle ${invoice.costCenter ? '' : 'empty-value' }`}
        items={costCenters || []}
        selectedValue={invoice.costCenter}
        tooltip={selectedCostCenter ? selectedCostCenter.name ?? '' : ''}
        getMenuItemValue={(costCenter: CostCenterDto) =>
          `${costCenter.costCenter} - ${costCenter.name}`
        }
        handleChange={handleCostCenterSelection}
        handleFilter={(costCenters, filterValue) =>
          costCenters.filter(
            (costCenter: CostCenterDto) =>
              costCenter.costCenter
                .toLowerCase()
                .includes(filterValue.toLowerCase()) ||
              costCenter.name.toLowerCase().includes(filterValue.toLowerCase()),
          )
        }
      ></DropdownWithFilter>
    </td>
  );
}

export default CostCenter;