import DatePicker from 'react-datepicker';
import './filter-bar.scss';
import { useTranslation } from 'react-i18next';
import DropdownWithFilter from '../../../elements/dropdown-with-filter/dropdown-with-filter';
import { AccountingNumberDto } from '../../../../models/accounting-number.dto';
import { CostCenterDto } from '../../../../models/cost-center.dto';

interface PropsType {
   onChangeFilterBar;
   fromDate: Date;
   toDate: Date;
   fromAmount: string;
   toAmount: string;
   costCenterValue: string;
   accountingNumberValue: string;
   accountingNumbers: AccountingNumberDto[];
   costCenters: CostCenterDto[];
}

const FilterBar = ({
   onChangeFilterBar,
   fromDate,
   toDate,
   fromAmount,
   toAmount,
   costCenterValue,
   accountingNumberValue,
   accountingNumbers,
   costCenters,
}: PropsType) => {
   const [translate] = useTranslation(['account', 'common', 'invoice']);

   return (
      <div id='container'>
         <div className={'flex-row d-flex mb-3'}>
            <div id='dateRangeHeader' className='filter-container flex-row d-flex'>
               <div className={'component-margin'}>
                  <b>{translate('common:search.dateRange')}</b>
                  <div className='inline content-margin'>
                     <DatePicker
                        selected={fromDate}
                        dateFormat='dd/MM/yyyy'
                        onChange={(date: Date) => {
                           onChangeFilterBar(
                              date,
                              toDate,
                              fromAmount,
                              toAmount,
                              accountingNumberValue,
                              costCenterValue,
                           );
                        }}
                     />
                  </div>
               </div>
               <div className={'component-margin'}>
                  <b>{translate('common:search.to')}</b>
                  <div className='inline content-margin'>
                     <DatePicker
                        selected={toDate}
                        dateFormat='dd/MM/yyyy'
                        onChange={(date: Date) => {
                           onChangeFilterBar(
                              fromDate,
                              date,
                              fromAmount,
                              toAmount,
                              accountingNumberValue,
                              costCenterValue,
                           );
                        }}
                     />
                  </div>
               </div>
            </div>

            <div id='amountRangeHeader' className='filter-container flex-row d-flex'>
               <div>
                  <b>{translate('common:search.amountRange')}</b>
                  <div className='inline content-margin'>
                     <input
                        type='number'
                        value={fromAmount}
                        onChange={(e) => {
                           onChangeFilterBar(
                              fromDate,
                              toDate,
                              e.target.value,
                              toAmount,
                              accountingNumberValue,
                              costCenterValue,
                           );
                        }}
                     ></input>
                  </div>
               </div>
               <div className='content-margin'>
                  <b>{translate('common:search.to')}</b>
                  <div className='inline content-margin'>
                     <input
                        type='number'
                        value={toAmount}
                        onChange={(e) => {
                           onChangeFilterBar(
                              fromDate,
                              toDate,
                              fromAmount,
                              e.target.value,
                              accountingNumberValue,
                              costCenterValue,
                           );
                        }}
                     ></input>
                  </div>
               </div>
            </div>
         </div>

         <div className='flex-row d-flex justify-content-between'>
            <div>
               <b>{translate('invoice:invoice.accountingNumber')}:</b>
               <div className='inline component-margin content-margin'>
                  <DropdownWithFilter
                     colorMode='dark'
                     items={accountingNumbers}
                     selectedValue={accountingNumberValue}
                     tooltip={accountingNumberValue ? translate(accountingNumberValue) ?? '' : ''}
                     getMenuItemValue={(accountingNumber: AccountingNumberDto) =>
                        `${accountingNumber.accountingNumber} - ${accountingNumber.name}`
                     }
                     handleChange={(item: AccountingNumberDto) => {
                        onChangeFilterBar(
                           fromDate,
                           toDate,
                           fromAmount,
                           toAmount,
                           item.accountingNumber,
                           costCenterValue,
                        );
                     }}
                     handleFilter={(accountNumbers, filterValue) =>
                        accountNumbers.filter(
                           (accountNumber: AccountingNumberDto) =>
                              accountNumber.accountingNumber
                                 .toLowerCase()
                                 .includes(filterValue.toLowerCase()) ||
                              accountNumber.name.toLowerCase().includes(filterValue.toLowerCase()),
                        )
                     }
                  ></DropdownWithFilter>
               </div>
            </div>

            <div className='flex-row d-flex align-items-center select-used-cost-center'>
               <b>{translate('invoice:invoice.costCenter')}:</b>
               <div className='inline component-margin content-margin'>
                  <DropdownWithFilter
                     colorMode='dark'
                     items={costCenters}
                     selectedValue={costCenterValue}
                     tooltip={costCenterValue ? translate(costCenterValue) ?? '' : ''}
                     getMenuItemValue={(costCenter: CostCenterDto) =>
                        `${costCenter.costCenter} - ${translate(costCenter.name)}`
                     }
                     handleChange={(item: CostCenterDto) => {
                        onChangeFilterBar(
                           fromDate,
                           toDate,
                           fromAmount,
                           toAmount,
                           accountingNumberValue,
                           item.costCenter,
                        );
                     }}
                     handleFilter={(costCenters, filterValue) =>
                        costCenters.filter(
                           (costCenter: CostCenterDto) =>
                              costCenter.costCenter
                                 .toLowerCase()
                                 .includes(filterValue.toLowerCase()) ||
                              costCenter.name.toLowerCase().includes(filterValue.toLowerCase()),
                        )
                     }
                  ></DropdownWithFilter>
               </div>
            </div>
         </div>
      </div>
   );
};

export default FilterBar;
