import React from 'react';
import {Navigate, Route, Routes} from 'react-router-dom';
import Dashboard from '../../components/pages/dashboard';
import RoutesWithNavbar from './routes-with-navbar';
import Profile from '../../components/pages/profile/profile';
import BankAccounts from '../../components/pages/bank-accounts/bank-accounts';
import Login from '../../components/pages/login/login';

const AzureAppRoutes = () => {
   return (
      <Routes>
         <Route path='/' element={<RoutesWithNavbar />}>
            <Route path='/' element={<Dashboard />} />
            <Route path='/profile' element={<Profile />} />
            <Route path='/bank-accounts' element={<BankAccounts />}/>
         </Route>
        <Route path='*' element={<Navigate to='/' />} />
        <Route path='/login' element={<Login />} />
      </Routes>
   );
};

export default AzureAppRoutes;
