import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { useKeycloak } from '@react-keycloak/web';
import { Protected } from './Protected';
import RoutesWithNavbar from './routes-with-navbar';
import Dashboard from '../../components/pages/dashboard';
import Profile from '../../components/pages/profile/profile';
import BankAccounts from '../../components/pages/bank-accounts/bank-accounts';
import { useEffect } from 'react';
import { setAccessTokenLocalStorage } from '../../utils/token';

const KeycloakAppRoutes = () => {
   const { initialized, keycloak } = useKeycloak();

   useEffect(() => {
      if (!initialized) return;

      const token = keycloak.token;
      token && setAccessTokenLocalStorage(token);
   }, [keycloak, initialized]);

   const router = createBrowserRouter([
      {
         path: '/',
         element: <Protected component={
            <><RoutesWithNavbar/><Dashboard /></>
         } />,
      },
      {
         path: '/login',
         element: <Protected component={
            <><RoutesWithNavbar/><Dashboard /></>
         } />,
      },
      {
         path: '/profile',
         element: <Protected component={
            <><RoutesWithNavbar/><Profile /></>
         } />,
      },
      {
         path: '/bank-accounts',
         element: <Protected component={
            <><RoutesWithNavbar/><BankAccounts /></>
         } />,
      },
   ]);

   if (!initialized) {
      return <div>Loading...</div>;
   }

   return <RouterProvider router={router} />;
};

export default KeycloakAppRoutes;