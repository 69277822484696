import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { ApprovalDto } from '../../../models/approval.dto';
import {RejectionDto} from '../../../models/rejection.dto';
import {dateTimeFormat} from '../../../utils/time-utils';

type Props = {
   approvals: ApprovalDto[];
   rejections: RejectionDto[];
};
const Status = ({ approvals, rejections }: Props) => {
   const [translate] = useTranslation(['account', 'common', 'invoice']);
   approvals = approvals.filter(a => a.active);
   rejections = rejections.filter(r => r.active);

   function getPending() {
      return <>{translate('common:common.status.pending')}</>;
   }

   function getApprovals(approvals: ApprovalDto[]) {
      return (
          <OverlayTrigger
              key={'top'}
              placement={'top'}
              overlay={
                 <Tooltip className='approval-tooltip'>
                    <div>
                       <b>{translate('invoice:invoice.approvedBy')}</b>
                    </div>
                    {approvals.map((approval) => (
                        <div key={approval.email}>
                           <span>
                              <b>
                                 {approval.firstName} {approval.lastName}
                              </b>
                              ({approval.email}) at {dateTimeFormat(approval.createdAt)}
                           </span>
                        </div>
                    ))}
                 </Tooltip>
              }
          >
             <b className={'approved'}>{translate('common:common.status.approved')}</b>
          </OverlayTrigger>
      );
   }

   function getRejection(rejections: RejectionDto[]) {
      return (<OverlayTrigger
          key={'top'}
          placement={'top'}
          overlay={
             <Tooltip className='approval-tooltip'>
                <div>
                   <b>{translate('invoice:invoice.rejectedBy')}</b>
                </div>
                {rejections.map((approval) => (
                    <div key={approval.email}>
                           <span>
                              <b>
                                 {approval.firstName} {approval.lastName}
                              </b>
                              ({approval.email}) at {dateTimeFormat(approval.createdAt)}<br/>
                              {approval.reason}
                           </span>
                    </div>
                ))}
             </Tooltip>
          }
      >
         <b className={'rejected'}>{translate('common:common.status.rejected')}</b>
      </OverlayTrigger>);
   }

   return (
      <>
         {rejections?.length ? getRejection(rejections) : approvals?.length ? getApprovals(approvals) : getPending()}
      </>
   );
};

export default Status;
