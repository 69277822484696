import { BaseQueryFn, FetchArgs, FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getBackendURL } from '../utils/loginProvider';

export const baseQuery = fetchBaseQuery({
   baseUrl: getBackendURL() + 'api',
   prepareHeaders: (headers) => {
      const token = localStorage.getItem('accessToken');
      if (token) {
         headers.set('authorization', `Bearer ${token}`);
      }

      return headers;
   },
});
export const baseQueryWithRedirect: BaseQueryFn<
   string | FetchArgs,
   unknown,
   FetchBaseQueryError
> = async (args, api, extraOptions) => {
   const result = await baseQuery(args, api, extraOptions);
   if (result.error?.status === 401) {
      window.location.href = '/login';
   }

   return result;
};
