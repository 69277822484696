import React, {useEffect} from 'react';
import {Controller, UseFormReset, UseFormSetValue} from 'react-hook-form';
import {Form} from 'react-bootstrap';
import {BankAccountDto} from '../../../models/bank-account.dto';
import {UseFormRegister} from 'react-hook-form/dist/types/form';
import {FieldErrors} from 'react-hook-form/dist/types/errors';
import {useTranslation} from 'react-i18next';
import {countries} from './countries';
import {CountryDto} from '../../../models/country.dto';
import DropdownWithFilter from '../../elements/dropdown-with-filter/dropdown-with-filter';
import {accountFields, FieldDefinition} from './add-edit-account-fields';
import {useGetAccountingNumbersQuery} from '../../../store/accounting-numbers/accounting-numbers.api';
import {AccountingNumberDto} from '../../../models/accounting-number.dto';

interface PropsType {
  editAccount: BankAccountDto | null | undefined;
  onSubmit: () => void;
  register: UseFormRegister<BankAccountDto>;
  errors: FieldErrors<BankAccountDto>;
  setValue: UseFormSetValue<BankAccountDto>;
  reset: UseFormReset<BankAccountDto>;
  control: any,
  accountErrors: { defaultMessage: string; field: string }[];
}

const AddEditAccount = ({
                          editAccount,
                          onSubmit,
                          register,
                          errors,
                          setValue,
                          reset,
                          control,
                          accountErrors = [],
                        }: PropsType) => {

  const [translate] = useTranslation(['account', 'invoice']);
  const { data: accountingNumbers } = useGetAccountingNumbersQuery();

  const getFieldError = (field: string) => {
    const errorMessage = accountErrors.find((error) => error.field === field)?.defaultMessage;
    return errorMessage ? <Form.Text className='error-text'>{errorMessage}</Form.Text> : null;
  };

  useEffect(() => {
    if (editAccount) {
      setValue('name', editAccount.name, {shouldValidate: true});
      setValue('bic', editAccount.bic, {shouldValidate: true});
      setValue('streetOrAddressLine1', editAccount.streetOrAddressLine1, {
        shouldValidate: true,
      });
      setValue('buildingNumberOrAddressLine2', editAccount.buildingNumberOrAddressLine2, {
        shouldValidate: true,
      });
      setValue('city', editAccount.city, {shouldValidate: true});
      setValue('country', editAccount.country, {shouldValidate: true});
      setValue('postalCode', editAccount.postalCode, {shouldValidate: true});
      setValue('iban', editAccount.iban, {shouldValidate: true});
      setValue('currency', editAccount.currency, {shouldValidate: true});
      setValue('accountingNumber', editAccount.accountingNumber, {shouldValidate: true})
    } else {
      reset({
        name: '',
        bic: '',
        streetOrAddressLine1: '',
        buildingNumberOrAddressLine2: '',
        city: '',
        country: '',
        postalCode: '',
        iban: '',
        currency: '',
        accountingNumber: '',
      });
    }
  }, [editAccount]);

  function getGroup(field: { name: string; required: boolean }) {
    return <>
      <Form.Label>{translate(`bankAccount.${field.name}`)}</Form.Label>
      <Form.Control
        onInput={e => {
          setValue(field.name as keyof BankAccountDto, (e.target as HTMLInputElement).value, {shouldValidate: true})
        }
        }
        {...register(field.name as keyof BankAccountDto, {
          required: field.required,
        })}
      />
      {errors[field.name] && (
        <Form.Text className='error-text'>
          {translate(`bankAccount.${field.name}Required`)}
        </Form.Text>
      )}
      {getFieldError(field.name)}
    </>;
  }

  function countyToString(country: CountryDto | undefined): string {
    if (!country) return '';

    return `${country.code} - ${translate(country.label)}`;
  }

  function accountNumberToString(accountingNumber: AccountingNumberDto | undefined): string {
    if (!accountingNumber) return '';

    return `${accountingNumber.accountingNumber} - ${accountingNumber.name}`;
  }

  function getCountyDropdown(field: FieldDefinition) {
    return <>
      <Form.Label>{translate(`bankAccount.${field.name}`)}</Form.Label>
      <Controller
        rules={{required: field.required}}
        control={control}
        name={field.name}
        render={({field: {onChange, value, onBlur},}) =>
          (
            <DropdownWithFilter
              className='form'
              items={countries}
              onBlur={onBlur}
              selectedValue={countyToString(countries.find(c => c.code === value))}
              getMenuItemValue={countyToString}
              handleChange={e => onChange(e.code, {shouldValidate: true})}
              handleFilter={(bankAccounts: CountryDto[], filterValue) =>
                bankAccounts.filter(c => countyToString(c).toLowerCase().includes(filterValue.toLowerCase()))
              }
            ></DropdownWithFilter>
          )}
      />
    </>;
  }

  const getAccountingNumberDropdown = (field: FieldDefinition) => {
    return <>
      <Form.Label>{translate(`invoice:invoice.${field.name}`)}</Form.Label>
      <Controller
        rules={{required: field.required}}
        control={control}
        name={field.name}
        render={({field: {onChange, value, onBlur},}) =>
          (
            <DropdownWithFilter
              className='form'
              items={accountingNumbers || []}
              onBlur={onBlur}
              selectedValue={accountNumberToString(accountingNumbers?.find(c => c.accountingNumber === value))}
              getMenuItemValue={accountNumberToString}
              handleChange={e => onChange(e.accountingNumber, {shouldValidate: true})}
              handleFilter={(accountingNumber: AccountingNumberDto[], filterValue) =>
                accountingNumber.filter(c => accountNumberToString(c).toLowerCase().includes(filterValue.toLowerCase()))
              }
            ></DropdownWithFilter>
          )}
      />
    </>;
  }

  const getFields = (field: FieldDefinition) => {
    switch (field?.type) {
      case 'country': return getCountyDropdown(field);
      case 'accountingNumber': return getAccountingNumberDropdown(field);
      default: return getGroup(field);
    }
  }

  return (
    <>
      <div className={'w-100 d-flex justify-content-center'}>
        <Form onSubmit={onSubmit} className='w-75'>
          {accountFields.map((field) => (
            <Form.Group className='mb-1' controlId={field.name} key={field.name}>
              {getFields(field)}
            </Form.Group>
          ))}
        </Form>
      </div>
    </>
  );
};

export default AddEditAccount;
