import {Button, Modal, Spinner} from 'react-bootstrap';
import React from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {InvoiceDto} from '../../../models/invoice.dto';
import {dateTimeFormat} from '../../../utils/time-utils';
import './history-dialog.scss';
import {useGetAmountChangedQuery} from '../../../store/invoices/invoices.api';
import UserName from './user-name';
import {RejectionDto} from '../../../models/rejection.dto';
import {ApprovalDto} from '../../../models/approval.dto';
import moment from 'moment';
import {AmountChangedDto} from '../../../models/amount-changed.dto';

interface Props {
  show: boolean;
  onHide: () => void;
  invoice: InvoiceDto;
}

const HistoryDialog = ({show, onHide, invoice}: Props) => {
  const [translate] = useTranslation(['account', 'common', 'invoice']);
  const {data: amountChanged = [], isLoading} = useGetAmountChangedQuery({invoiceId: invoice.invoiceId});
  const records = [...invoice.approvals, ...invoice.rejections, ...amountChanged];

  records.sort((a, b) => moment(a.createdAt).diff(moment(b.createdAt)))

  function getApproval(approval: ApprovalDto) {
    return <Trans i18nKey="invoice:invoice.history.approvedRecord"
                  tOptions={{
                    approvedAt: dateTimeFormat(approval.createdAt),
                  }}
                  components={[
                    /* eslint-disable-next-line react/jsx-key */
                    <span style={{color: 'green'}}/>,
                    /* eslint-disable-next-line react/jsx-key */
                    <UserName firstName={approval.firstName} lastName={approval.lastName} email={approval.email}/>]}
    />;
  }

  function getRejection(rejection: RejectionDto) {
    return <>
      <Trans i18nKey="invoice:invoice.history.rejectedRecord"
             tOptions={{
               rejectedAt: dateTimeFormat(rejection.createdAt),
             }}
             components={[
               /* eslint-disable-next-line react/jsx-key */
               <span className='text-danger'/>,
               /* eslint-disable-next-line react/jsx-key */
               <UserName firstName={rejection.firstName} lastName={rejection.lastName} email={rejection.email}/>]}
      />
      <br/>
      {rejection.reason}
    </>;
  }

  function getAmountChanged(a: AmountChangedDto) {
    return <Trans i18nKey="invoice:invoice.history.amountChangedRecord"
                  tOptions={{
                    createdAt: dateTimeFormat(a.createdAt),
                    oldAmount: a.oldAmount,
                    newAmount: a.newAmount
                  }}
                  components={[
                    /* eslint-disable-next-line react/jsx-key */
                    <span className='text-warning'/>,
                    /* eslint-disable-next-line react/jsx-key */
                    <UserName firstName={a.firstName} lastName={a.lastName} email={a.email}/>]}
    />;
  }

  function isAmountChanged(record: ApprovalDto | RejectionDto | AmountChangedDto): boolean {
    return 'oldAmount' in record;
  }

  function isRejection(record: ApprovalDto | RejectionDto | AmountChangedDto) {
    return 'reason' in record;
  }

  function getRecord(record: ApprovalDto|RejectionDto|AmountChangedDto) {
    if (isAmountChanged(record)) {
      return getAmountChanged(record as AmountChangedDto);
    }
    if (isRejection(record)) {
      return getRejection(record as RejectionDto);
    }
    return getApproval(record as ApprovalDto)
  }

  function getBody() {
    return <>
      <div>
        {translate('invoice:invoice.history.created', {createdAt: dateTimeFormat(invoice.createdAt)})}
      </div>
      {records.map(record => (
        <div key={typeof record + record.createdAt} className='pt-sm-1'>
          {getRecord(record)}
        </div>
      ))}
    </>;
  }

  return (
    <Modal show={show} onHide={onHide} dialogClassName='history-dialog'>
      <Modal.Header>
        <Modal.Title>{translate('invoice:invoice.history.label')}</Modal.Title>
      </Modal.Header>
      <Modal.Body className='dialog-body'>
          {isLoading ? <Spinner/> : getBody()}
      </Modal.Body>
      <Modal.Footer>
        <Button variant='primary' onClick={onHide}>
          {translate('common:common:ok')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default HistoryDialog;