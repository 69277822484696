import {InvoiceDto} from '../../../models/invoice.dto';

export class ColumnConfig {
  label: string;
  className: string;
  sortBy: string;
  show: boolean;
  renderCell: (invoice: InvoiceDto, onclick: () => void) => JSX.Element;

  constructor(label: string, className: string, sortBy: string, show: boolean, renderCell) {
    this.label = label;
    this.className = className;
    this.sortBy = sortBy;
    this.show = show;
    this.renderCell = renderCell;
  }
};