import {countries} from './countries';

export interface FieldDefinition {
  name: string;
  required: boolean;
  options?: any[];
  type: 'input' | 'country' | 'accountingNumber';

  getSelected?: (value: any) => string;
}

export const accountFields: FieldDefinition[] = [
  {name: 'name', required: true, type: 'input'},
  {name: 'iban', required: true, type: 'input'},
  {name: 'currency', required: true, type: 'input'},
  {name: 'bic', required: true, type: 'input'},
  { name: 'country',
    required: true,
    type: 'country',
    options: countries,
  },
  {name: 'city', required: true, type: 'input'},
  {name: 'streetOrAddressLine1', required: true, type: 'input'},
  {name: 'buildingNumberOrAddressLine2', required: false, type: 'input'},
  {name: 'postalCode', required: true, type: 'input'},
  {name: 'accountingNumber', required: false, type: 'accountingNumber'}
];

